export const ourCultureData = {
    "event": [
        {
            "eventId": 1,
            "category": "Festival",
            "title": "Diwali",
            "description": "In a symphony of colors and lights, our team comes together, igniting joy and prosperity as we celebrate Diwali. Each diya, each laugh, each shared moment, a reminder of the brightness we bring into each other's lives, illuminating the path ahead with hope and happiness",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 2,
            "category": "Festival",
            "title": "Holi",
            "description": "Bright smiles and splashes of color, capturing the essence of our team's Holi celebration, where laughter dances on the breeze and friendships are painted in every hue.appiness",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 3,
            "category": "Festival",
            "title": "Lohri",
            "description": "Glimpses of warmth and cheer, as we danced around the bonfire, hearts aglow with the spirit of Lohri. A celebration of unity, laughter, and prosperity, woven into the fabric of our team's joyous moments.",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 4,
            "category": "Festival",
            "title": "Independence Day",
            "description": "`Adorned with flags and balloons, our celebration hall becomes a canvas of patriotism, where every decoration whispers stories of freedom and unity, echoing the spirit of Independence Day shared by our team.",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 5,
            "category": "Weekend Fun",
            "title": "Movie Nighty",
            "description": "Popcorn, drinks, and laughter fill the air as our team unwinds together for a movie night, creating memories that sparkle as brightly as the silver screen.",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 6,
            "category": "Weekend Fun",
            "title": "Christmas Lunch",
            "description": "In the cozy glow of our Christmas lunch, laughter fills the air as gifts exchange hands, weaving threads of joy and gratitude among our team, making memories that will twinkle in our hearts for years to come..",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 7,
            "category": "Birthday Celebration",
            "title": "Birthday Party",
            "description": "Celebrating birthdays in the office brings joy, camaraderie, and shared memories, as we honor our colleagues and strengthen our team's bond with laughter and cheer.",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 8,
            "category": "Activities",
            "title": "Cricket Match",
            "description": "Our Saturday event was hit. Relieve the excitement of our thrilling cricket event- packed with team spirit and unforgettable moment.",
            "date": "2024-07-15T11:49:24.836Z"
        },
        {
            "eventId": 9,
            "category": "Activities",
            "title": "Diwali Games",
            "description": "Teamwork shines as we unite, in the spirit of Diwali's light,Together, we strive and thrive, in this joyous flight.Amidst the challenges we face, bonds grow stronger still, For in each game and every laugh, we find a thrill.",
            "date": "2024-07-15T11:49:24.836Z"
        }
    ],
    "images": [
        {
            "eventImageId": 20,
            "eventId": 1,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_978811.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=mpXD4OC6WIdc7sIZpzKO%2FXbtJTTxvyY6go40CHGPDVjfns9vxRcIhJjZ6IDo0guG%2B3PuOSvJKTP3Vve2G0xd3vfWKchL6%2BsRYs2ovCj5zYpjFog8BQxulI%2FrjduV0V6CUqFNpRmGI%2Bidouskg2oRrlDvVLJPUcyEL5Bh0JWZnY5qgIfZZ%2F1GpbRyMbzGZ3gczY9QXSDKd8DBY6pPO7jJ6XEj7%2B4qtde4k8AWRGrEPsvkoI%2Byx83VtmRFSffAN5WMFdXCegfmHK%2Br5pY3%2Fxtjwx0QBU8XCMhk1Unt4EfqLSnOtTL0EUloXNTSSlInNRRqA7Yh%2FiA2%2FDFVwBO6Zj63Tw%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 21,
            "eventId": 2,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_729686.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=zv6YnuamASsbYvKOl7O%2FpyLuerJgpVNHFzIu5bh1qLi9vlHnsSgX0HFg5tlp8I3BepFzH7E%2BE0IcIyjIJoq7ONz8JKuopbRumLW%2B%2Fir4vzQ9R4lbOSR8YNErXV0is45XVfXJ0QRl3gAcTkLvMDVYrxPbBLv3XzeVjbOFn6LYYHEbNe1q7DdtwzgTvLwjGpcmiwnlBDT3Rc2FpxDsN22CZpINvC0IAfySZ4kZftTf1ZBTy7zSeL%2BjCeiZVRfLl6h1UDYdLqTWQa96PxITFTu7IX0N54G%2F7hDaYM9VsgTSuiWiu2Dk7BO7oppNFxEtAXzajJv8Am%2FHovVYqDdsh39D%2FQ%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 22,
            "eventId": 3,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_403937.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=ZIrvoGk11xu40om4n8Ql05B5SOUcfGLA1sODKqbhQd8J3CwaUpunHSfPacRFrh3beSGlu5v%2FvVUL%2FvoD3OZjlmNQ3rJgRelB%2B1ATbx6DkzwJ4eOnYkAMxROoCNEJuUua3SxWsMkCw5ffFMtp6k7yqfLznx8JxXB8V2Z9J8fO2xKpphLdhqkZAHz%2BMesa3SDfYX%2Ba8Bbuevo1rpSf%2FIAccJ%2Bn%2BoxZPyDxdCupBvDW%2F4vnnCgu%2Fy%2BTT2cXlKX2DzXK%2FCrPm8AkbodLnY2qE2bvza28h%2B5AkQLpzI%2BdlSaJce9Rp4GqkiS0SmSLdwlwUfBWxIm5NK7r9jRBkZo1x%2B8d4A%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 23,
            "eventId": 4,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_608611.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Jo%2BgIIWDIkhb2pB19E6uUchH%2FRwb2jLRxxWEM2xV%2FtfgFYhcNi8ks1GvlvoN5F%2FoB5AHxFBpoXq%2BIqJ%2FzVbqsP4FWkdaXV40iBtZ%2FEDLvjovtUSTcpsfn%2BfWws5ypeJvgU0xJLLbL0mcMxU%2FgpZNvK4%2BgawgouXX4cS4vVOUWxXyeuv3ipEl%2F8dg5I%2B6eDHS5CyytjJ3JxRrXNM14ikiDReVPoewup7rpJIy%2F7NDqTzMk%2F6fjlGPOMlkZLDZS1mXZWmBt6iSF3qEd3W38vTHBJikjSgAWSNhw2katjcNqVnArZWjHnt%2Fnh6rtI4GV4hiPXGDCVIR6PnRy2Pn15tlHA%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 24,
            "eventId": 5,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_407283.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=CXQovYz6X%2BGxbIuwrE7CObk760%2BEgNFyjWayILk1woBuVcjKCgBL7Pc9bd1SdOq%2FjxwEJwxFnkw5RekhVFwo55cZQtfMWtUuxEoh6Bthurj9CktjBsXrgh2FTFr4ZXQS%2FNog7hdoNm2uaC2wE%2BFCU%2BkXWI8NbHQUE8tIuiJUiWtNJOQz3N4%2BPURwnuK0bTF6QVacObf5BFOrP9y3ll2fEfzBEvNF%2F%2BJGYLK0MQ3bhelPpAKbWZs3J8shh4yjNM5ib75tsufbpR8rfirylWJ2mrpAEBlhm8NAaE9fFeDVG7sS7aGaMjbc%2B3crYKJ2pJC3oYQcuEV4kXo4kDeWcqbSzg%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 25,
            "eventId": 6,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_192742.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=mczlBe6vvusCWE0hlEtNvYV1dmjdHFo%2BU3EfRqgTcG9S9Rf7dbGZixtU4vki1ueKLb704pm4tyzDjeUJS5vnwrPN0kX0nHScDovb%2B0YQsoQ%2BoM7pSueMDGVXLflxZzcapkjV%2FttBz1LlytXJr19luHVuMb9dwaVmGu7g1YKodJphmDHidBaGMyOz3MEDSFef3i%2BoYdPHBpC0Oo6Yl1X%2BnyoRaGCw5TefvtaQXYlbRS4IBKCxHbfVj%2BkcZPXMQsdLG2xrHPQwbbGWpCL%2BSFXFdxliON9Bgu73r3PmoyfCYjzt09MKEkCdfIt%2FBww3INrdhBFNgy5R4nO5ZHrz2%2BsWvg%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 26,
            "eventId": 7,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/3_879151.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=gnf%2BwrYgAD4AVXj05pzDHaBMctSEVbRzDZAwK6%2FYbWY1moSA9TOYWTKK4yW8Zx7GAlHVBv78Eh6LKyB6sOfFxepwPWTjeQiEOx8X66d7YSYa5HHxgq31yDgACjdZ%2FvLd0g%2FyBRXjy%2Bj1lrCn7ICjNIRswPiAu0dHaDXHCQY1Cuhx4RCLNyMHyCN1FUpAZ7ghpdUvp9ACsSJ83fVz2buRLHjQC9QbxiO3gFxsRwn98VxSnN6BNXwrS7WrKqcwyhmv17xRfRsA2wCaXwjuxmcuHIZCem9gSP1q8KdHju1i6XZKuMvbrsDblhHfGUgLaox8He86tlhn%2FxZ7ZtSh5qd5rQ%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 27,
            "eventId": 8,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/1_262748.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=310nTTI6wPe35mRfZ5l9XadwoiazImYEHEulLrBi02mjrbVEB80ZVMwzVyQDJYweMlmltdLeh6Xy2h%2FkG%2BtrI5zvPW1jivhhFjpdIeqLTMEosohL0m9aAjED4%2BwB5MHVQBIbz2N0ZJ7nQ9kbe5j6UGNg5n5jkadNmLDaZyIbiIHktR8rkrtDMku8wK0JVYSKa7LAxDev2408p2C0tec%2F%2B97yrqLEOs%2BTldwgpwT2A4kaVZ8GuWeP2qPtF6e6WBU9gCxXQRPKNYkDVZEuY0eC8dHLierYSabQpHTmRrYGgR8AhWA6ZaB1yO57Cqulk3MXw57RN2UEA2DQ0gu7L8GIkQ%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 28,
            "eventId": 9,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/3_28930.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=FNL5NEu2d2suBPrDLk5azLlSuNq02FLyczOnCzlEFTLUsiw1v9%2Bh0FgyZN0WiMvLZdl%2BH8E46PVRaS1Fx%2BgddoYRKaXtrOcSRlqyo5%2FaRNXdkqq4PgfeGzrTNDD5YsF3jSMwglGVjBdDngOGtse5YvV%2BIIZ55Jr4jz3BlqvqUi8okmED6MOkKK7lv9D9JZKvEG8p0q%2Bk7GEXtNIRx0iiNzxgjp%2FcJf8E%2FlYVdX0QxViQ8EfZaVCyucugt3CneHGL852kyInt%2BWfiu2dK6LJ0qsgYdcbQOvfqEH%2FfMfOpw%2FxlcWIkGld%2FDGQmx3BOFSR5oA24%2FZgdi0BlBf5vv%2FBpIg%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 29,
            "eventId": 1,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/2_725299.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=TrEqLKxYfBx%2FEGrt92jsm3pL5BoMtsSfRJ4OTzre1hcmPLfDtMW%2FBoIM0mnqcB2tCMVjd9vqvdu4UCiEpgMJ0MRtS7oehKon0vtcrE9UNAx%2FhVH8hLqt8We31ZQG7BaS6fPmqtCu2%2FAa7C6GvSA5gBuffYkJW3JUQv9lz2SJAUT4KR810JjQdlKKI%2FTCtv4EJMOd2kPfP%2F8pY1H6EGiDl4%2BSBliwKgDP4GNeG5rbPRen2Y91LZ4G7hiTKyh45egEp9VX1ow0vzeRRq25BPw5M6mMmt31yYPs%2F%2BhJ0TnSLoGF92PVomSUYd1eLvuRlT%2FsQDDT5xSFVNFMaEzGCKeNng%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 30,
            "eventId": 1,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/3_252681.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=jTYEqUZUEqXTme1OffWMMwtX%2FfcCK9rwJK7D8OvXpeEHYrwc9sZ8xzq0WkIbOomNaGv0YMMKaYnm0cCfcEqjLrtEftwx7zTSQBMBnhyD3n7UagOyIP650dOXOMewqzGaSDNiGRQaiwd172zJXNgr95svA76%2FXBZFDQoj%2Fff4vSm2TKziIFNXMGXkAbYDFVg7mFAxZlY0EeKG1%2F%2FLqsMoVawNEooB1uoGtVe%2BH7m%2Fn7%2Bj0b5q6gHz6kzzWsl5guIpRKiFq9Cw2m1X0KKD8QLydYehhXE6y%2Buclkrn%2F2mGCdYm5dfoSoB7FvQf2rXbQWpTD0A7%2BX0sCNNer%2Fk6MmrXMw%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 31,
            "eventId": 1,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/4_372938.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=0M62wVxlcfBsf6vT6C4kJZ4R%2Fr7EbfrORnJljlMTrEa1fDF5rOexytCPw2%2BugfE8O1w7%2FCIvDr89%2FBbHeaVZF%2F3tdO94SRNURaFUXtHjRoB11LcqmF80%2FANB5fNw8Xm2tb2kRyyaHkI3Ou6ouxSrCPBnqd9jyRyfYbAW81sTZSXGqMI1iQHc91cjIpqgIxOmj%2Bams1B8FKZqshJ4%2FurSt6fhtA18uA%2BEqgTI%2F3kVr4PFUaQRdV3wk05I3Z%2BCz6QJFppPogUaR5JdzpNeIGuuTFTYpI79QSXCWLCUWueHgj%2BM%2B2MP16iiplVuYKmiMb6cTkKHh7NaIc34kBc3cA1rEg%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 32,
            "eventId": 2,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/2_453352.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=JFZ1Z6Akbx1FvZ4v5o62vNixxAOBWQ08bbAkfVVxxzHdBYOfSd%2Fa1ft2HTGH9Lif7OGf8qJnBPmPSH0XISeTIAlyF9xBAbTpJRYwZu33Dx5P%2FfJTwKek7N3RLBu9he8zCsnmwwWgDYvt69qzzcRR7SzqF0czD4U5ODXnSB%2FqEHy47UOKL84dP7vbpKoGx4wFxKZYxg5nFnG%2F28a5S5qs00DUY%2Fs0TWuDU102BwLSRFFiUMC4ttUh8IWqcRhfh5wKZc8Z5omA1nDFhA99dSHV2Rurqq5hH6Oyz5Pu8TMWZkQTCjG0qb%2BhKNKFIoV%2BLxjUsfYcyNpcCYZ6s35mMou1nA%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 33,
            "eventId": 2,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/3_476236.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=U%2F0UV8GP5qpPpQlTUSdqRXxZH4B7I%2ByWwYZNjf3KRngcYI0CxpOMhRIszYzSfNcuM431sYfWGDIxQ30B92q9GBL3TPWzDJX7FPXWbi0tQBNOfi8bSxVy66v9AIRevOMgcm6%2BmOmEFmyiKuTWl%2FmDgOVhpZKEKzRdIkYdSVvAvgB72VS1TmZer4EkCNXmE8iRbK575K50lKDqyVF3KwvAk5V9cksQlhKGG0xAolDrDCsJieU6ekXX233Y1nqNAVip0n5cqj1puuQlIe9cpAZaW2viWGCCVVZKJxH%2FxuioAKHRR1IvsFykuVec3pnQPpngq%2BjDEUgjGHE9Z%2F0%2FgTRUUg%3D%3D",
            "highlight": true,
            "coverImage": true
        },
        {
            "eventImageId": 34,
            "eventId": 2,
            "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/4_424144.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Ovd86DLDCZPOWiq3YvjEtNgY%2FcM9szNjJ%2FlcFnVH1juCy1F3EVQ5DFCswfFTBytJX%2FPHO8M%2F1ENExS9vaP1s3tHvsegRAWh8Y4LmVkPGZNwpvaiWzefrvl8gWUjBxFav%2Bfl7Qh6H4vJlhL%2B3fWmF2saSbycaYTKh6sPKO5247Msp%2F0JAVf7kAnbEIFYcW7hjNPYy0T8SNfEv%2FFdJUDOlGXtwUN1okV7UDMs7d3jnDSNQo5EIRa0hmriFgkJuRMkjcZ%2FhHbK%2FqzL%2F010ZMvtccLqlONevad98T%2BcjYTzZfpEphQVzdvw7sJc%2FrTZSPzhWQcYFO0rvp54CdZp%2F97Y6jw%3D%3D",
            "highlight": true,
            "coverImage": true
        }
    ]
}
