import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '@app/constants'

const initialState = {
  team: {},
}

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    //
    getTeam (state) {
      state.team.status = RequestStatus.RUNNING
    },
    getTeamSuccess (state, action) {
      state.team.status = RequestStatus.SUCCESS
      state.team.data = action.payload
    },
    getTeamFailed (state, action) {
      state.team.status = RequestStatus.ERROR
      state.team.error = action.payload
    }
    //
  }
})

export default slice.reducer
export const { getTeam, getTeamFailed, getTeamSuccess } =
  slice.actions
