import React from 'react'
import { Stack } from '@mui/material'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay
} from 'swiper/modules'

// Import the custom CSS file
import './imageSwiper.css'

const ImageSwiper = ({ slideImages }) => {
  return (
    <Stack className='image-swiper' py={6} width={'100%'} alignItems={'center'}>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        flexWrap={'wrap'}
        overflow={'hidden'}
        width={'100%'}
        direction={'row'}
      >
          <Swiper
            simulateTouch={true}
            allowTouchMove={true}
            loop
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={slideImages?.length < 4 ? 1 : 'auto'}
            autoplay={{
              delay: 0,
              disableOnInteraction: false
            }}
            speed={2500}
            spaceBetween={10}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 1,
              slideShadows: true
            }}
            modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
            className='mySwiper'
          >
            {slideImages?.map((slide, index) => (
              <SwiperSlide key={index} style={{ borderRadius: '40px' }}>
                <Stack
                  overflow='hidden'
                  position='relative'
                  zIndex={1}
                  alignItems='center'
                  justifyContent='center'
                  height='100%'
                  width='100%'
                >
                  <img
                    src={slide.image}
                    alt={slide.name}
                    style={{
                      height: '100%',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
      </Stack>
    </Stack>
  )
}

export default ImageSwiper
