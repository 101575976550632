import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { getCulture } from '@app/redux/slices/cultureSlice'
import { selectCultureState } from '@app/redux/selectors'
import { Iconify } from '@app/components'
import { DarkGradientText, GradientText, Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import homeImg1 from '../../images/about/img1.jpg'
import homeImg2 from '../../images/about/img2.jpg'
import Footer from '../footer/footer'
import ImageSwiper from './image-swiper'
import LocationPage from './location-page'

export const Data = [
  {
    id: 'stack1',
    icon: 'fa6-solid:arrows-to-circle',

    caption:
      'Within our office environment, ideas, talents, and opportunities Converge, creating a dynamic space for growth and achievement.'
  },
  {
    id: 'stack2',
    icon: 'material-symbols-light:circles-outline',

    caption:
      'We serve Synergetic Nexus, every project, every meeting, and every idea exchange is infused with the power of teamwork and cooperation'
  },
  {
    id: 'stack3',
    icon: 'lucide:heart-handshake',
    caption:
      "Our Hospitable Workspace is more than just a place to work—it's a community where kindness and support are woven into the fabric of everyday interactions."
  },
  {
    id: 'stack4',
    icon: 'streamline:ai-science-spark',

    caption:
      'Our space is a bustling Dynamo of productivity and innovation, where every interaction sparks creativity and drives progress.'
  }
]

const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

const AboutUs = () => {
  const isMobile = useResponsive('down', 'sm')
  const isTab = useResponsive('between', 'sm', 'md')
  const dispatch = useDispatch()
  const isDark = useSelector(state => state.theme.isDark)
  const cultureData = useSelector(selectCultureState)
  const highlightImages = cultureData?.images.filter((img) => {
    if (img?.highlight) {
      return img
    }
  })

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 })
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.4
  })

  useEffect(() => {
    dispatch(getCulture())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        flexWrap={'wrap'}
        style={{
          background:
            '  linear-gradient(240deg, rgba(214, 229, 234, 0.5) 20%, white 0%, rgba(214, 229, 234, 0.5) 20%, white 40%)'
        }}
      >
        <Stack
          position={'relative'}
          alignItems={'center'}
          mt={10}
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <Stack position={'relative'} zIndex={0} top={2}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.aboutUs} />
            ) : (
              <GradientText text={Strings.page_title.aboutUs} />
            )}
          </Stack>

          <Stack
            position={'relative'}
            zIndex={1}
            top={isMobile ? -25 : -55}
            textAlign={'center'}
          >
            <Typography
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
              fontWeight={'bold'}
            >
              {Strings.page_description.knowDetails} <br />{' '}
              {Strings.page_description.ourCompany}
            </Typography>
          </Stack>
        </Stack>

        {/* -------------detail-------------- */}
        <Stack width={'90%'} id='home' direction={'row'}>
          {/* ---------left part-------- */}
          <Stack
            alignItems={'start'}
            justifyContent={'center'}
            flex={1}
            position='relative'
            sx={{ display: { xs: 'none', md: 'flex' } }}
            component={motion.div}
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            <Stack
              width={'55%'}
              sx={{
                position: 'absolute',
                left: '190px',
                zIndex: 1,
                boxShadow: '-20px -20px 0px rgba(58, 193, 239, 0.1)'
              }}
            >
              <img
                src={homeImg1}
                alt='homeImg1'
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </Stack>

            <Stack
              width={'70%'}
              sx={{
                zIndex: 0
              }}
            >
              <img
                src={homeImg2}
                alt='homeImg2'
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </Stack>
          </Stack>

          {/* ---------right part-------- */}

          <Stack
            flex={1}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
            component={motion.div}
            initial={{ opacity: 0, x: 40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            <Stack
              alignItems={(isTab || isMobile) && 'center'}
              flexWrap={'wrap'}
              p={4}
              position={'relative'}
              gap={2}
              display={'flex'}
            >
              <Stack
                position={'relative'}
                flexWrap={'wrap'}
                textAlign={(isTab || isMobile) && 'center'}
                alignSelf={isMobile ? 'center' : 'start'}
              >
                <Typography
                  variant={'h2'}
                  color={isDark ? 'white' : 'black'}
                  fontWeight={'bold'}
                  lineHeight={1}
                >
                  We are Creative Digital Agency Based in India
                </Typography>
              </Stack>
              <Stack
                gap={2}
                position={'relative'}
                textAlign={(isTab || isMobile) && 'center'}
              >
                <Typography
                  variant='body1'
                  color={isDark ? 'lightgray' : 'gray'}
                >
                  Our journey began with a simple yet profound vision: to
                  empower individuals and businesses alike through technology.
                  Established with a passion for innovation and a commitment to
                  excellence, our company has evolved into a trusted partner for
                  clients worldwide.
                </Typography>
                <Typography
                  variant='body1'
                  color={isDark ? 'lightgray' : 'gray'}
                >
                  With a team of dedicated professionals, we strive to deliver
                  cutting-edge solutions tailored to meet the unique needs and
                  challenges of our clients.
                </Typography>
                <Typography
                  variant='body1'
                  color={isDark ? 'lightgray' : 'gray'}
                >
                  From pioneering startups to established enterprises, we work
                  closely with each partner to unlock new opportunities and
                  achieve their goals.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* --------------responsive top screen----------------- */}

        <Stack
          sx={{ display: { xs: 'flex', md: 'none' } }}
          py={5}
          width={'90%'}
          alignItems={'center'}
          justifyContent={'center'}
          flex={1}
          position='relative'
        >
          <Stack
            width={'35%'}
            sx={{
              position: 'absolute',
              left: '50%',
              zIndex: 1,
              boxShadow: '20px -20px 0px rgba(0, 0, 0, 0.2)'
            }}
          >
            <img
              src={homeImg1}
              alt='homeImg1'
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                objectFit: 'cover'
              }}
            />
          </Stack>

          <Stack
            width={'50%'}
            sx={{
              zIndex: 0
            }}
          >
            <img
              src={homeImg2}
              alt='homeImg2'
              style={{
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover'
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* -------------our environment----------- */}

      <Stack>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          my={3}
          component={motion.div}
          variants={headerVariants}
          initial='hidden'
          animate={headerInView ? 'visible' : 'hidden'}
          ref={headerRef}
        >
          <Typography
            color={isDark ? 'white' : 'black'}
            variant={isMobile ? 'h3' : 'h2'}
            fontWeight={'bold'}
          >
            {Strings.page_description.ourEnvironment}
          </Typography>
        </Stack>
        <Stack
          flexWrap={'wrap'}
          direction={'row'}
          gap={5}
          alignItems={'center'}
          justifyContent={'center'}
          component={motion.div}
          variants={containerVariants}
          initial='hidden'
          animate={inView ? 'visible' : 'hidden'}
          ref={ref}
        >
          {Data?.map(data => (
            <Stack
              direction={'row'}
              px={isMobile && 2}
              key={data.id}
              gap={2}
              width={'530px'}
              height={isMobile ? '' : '160px'}
              alignItems={'center'}
              justifyContent={'start'}
              component={motion.div}
              variants={childVariants}
            >
              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                color={isDark ? 'white' : '#03c1fe'}
                height={'80px'}
                minHeight={'80px'}
                width={'80px'}
                minWidth={'80px'}
                borderRadius={'50%'}
                backgroundColor={isDark ? 'black' : '#F2FCFF'}
              >
                <Iconify width={35} icon={data.icon} />
              </Stack>

              <Stack flexWrap={'wrap'}>
                <Typography
                  textAlign={'start'}
                  variant='body1'
                  color={isDark ? 'lightgray' : 'gray'}
                >
                  {data.caption}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {/* -------------slide images------------- */}
      <ImageSwiper slideImages={highlightImages} />
      {/* --------------location------------- */}
      <LocationPage />
      {/* ----------------footer--------------- */}
      <Footer />
    </>
  )
}

export default AboutUs
