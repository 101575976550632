import React, { useState } from 'react'
import { Box, Grid, Stack, Typography, Dialog, IconButton } from '@mui/material'
import useResponsive from '@app/constants/use-responsive'
import CloseIcon from '@mui/icons-material/Close'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

const DisplayImages = ({ data, title }) => {
  const isMobile = useResponsive('down', 'sm')
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [imagesArr, setImagesArr] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.01 })
  const { ref: childRef, inView: childInView } = useInView({
    triggerOnce: true,
    threshold: 0.01
  })

  const handleOpenDialog = (index, data) => {
    setImagesArr(data)
    setSelectedImageIndex(index)
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <Stack alignItems={'center'} justifyContent={'center'}>
        {/* --------------All---------------- */}
        {title === 'All' && (
          <Stack
            backgroundColor='rgba(59, 130, 246, 0.02)'
            width={'90vw'}
            mb={4}
            p={3}
            direction={'row'}
            gap={3}
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
            component={motion.div}
            variants={containerVariants}
            initial='hidden'
            animate={inView ? 'visible' : 'hidden'}
            ref={ref}
          >
            {data?.map((image, index) => (
              <motion.div variants={childVariants} key={index}>
                <Stack
                  key={index}
                  width={'250px'}
                  height={'200px'}
                  overflow={'hidden'}
                  position={'relative'}
                  onClick={() => handleOpenDialog(0, [image])}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    alt=''
                    src={image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Stack>
              </motion.div>
            ))}
          </Stack>
        )}
        {/* ----------------festival----------------- */}
        {title !== 'All' && (
          <Stack
            my={3}
            gap={5}
            component={motion.div}
            variants={containerVariants}
            initial='hidden'
            animate={childInView ? 'visible' : 'hidden'}
            ref={childRef}
          >
            {data?.map((item, index) => (
              <Stack
                key={index}
                backgroundColor='rgba(59, 130, 246, 0.04)'
                width={'90vw'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid container width={'100%'} direction={'row'}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    display='flex'
                    flexDirection='column'
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={4}
                    gap={2}
                    component={motion.div}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                  >
                    <Typography color={'primary'} variant='h3'>
                      {item?.type}
                    </Typography>
                    <Typography
                      width={isMobile ? '80vw' : '35vw'}
                      fontSize={'15px'}
                      textAlign={'center'}
                      color={'gray'}
                    >
                      {item?.caption}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    p={3}
                    display='flex'
                    justifyContent={'center'}
                    alignItems={'center'}
                    component={motion.div}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                  >
                    <Stack
                      borderRadius={'10px'}
                      width={'80vh'}
                      height={'50vh'}
                      overflow={'hidden'}
                      position={'relative'}
                    >
                      {item?.image && item?.image[0] && (
                        <Box
                          component='img'
                          alt=''
                          src={item?.image[0]}
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Stack
                  width='85vw'
                  m={4}
                  p={3}
                  direction='row'
                  gap={3}
                  sx={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                    scrollbarWidth: 'none'
                  }}
                >
                  {item?.image?.map((img, index) => (
                    <motion.div variants={childVariants} key={index}>
                      <Stack
                        borderRadius={'10px'}
                        key={index}
                        width='20vw'
                        height='32vh'
                        overflow='hidden'
                        position='relative'
                        flexShrink={0}
                        onClick={() => handleOpenDialog(index, item?.image)}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <img
                          alt=''
                          src={img}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </Stack>
                    </motion.div>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              color: 'white',
              '&:hover': {
                color: theme => theme.palette.primary.main,
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          {imagesArr.length > 0 && imagesArr[selectedImageIndex] && (
            <Box
              component='img'
              alt=''
              src={imagesArr[selectedImageIndex]}
              sx={{
                height: '80vh',
                objectFit: 'cover',
                backgroundColor: 'transparent'
              }}
            />
          )}
        </Dialog>
      </Stack>
    </>
  )
}

export default DisplayImages
