import axios from 'axios';

import * as Config from '@app/configs'

class HttpService {
  constructor() {
    this.http = axios.create({
      baseURL: Config.BASE_URL + Config.API_PREFIX,
      timeout: Number(Config.API_TIMEOUT),
    });

    this.authToken = null;

    this.interceptRequests();
    this.interceptResponse();
  }

  interceptRequests() {
    this.http.interceptors.request.use(
      async (reqConfig) => {
        console.log(`[Http.Request: ${reqConfig.url ?? ''}]`, reqConfig);
        if (this.authToken) {
          reqConfig.headers.Authorization = `Bearer ${this.authToken}`;
        }
        return reqConfig;
      },
      (error) => Promise.reject(error)
    );
  }

  interceptResponse() {
    this.http.interceptors.response.use(
      (response) => {
        console.log(`[Http.Response: ${response.config.url ?? ''}]`, response);
        return response.data;
      },
      (error) => Promise.reject(this.handleApiError(error))
    );
  }

  // Handling error
  handleApiError(error) {
    try {
      if (error.response) {
        if (error.response.data.StatusCode === 500) {
          return {
            message: 'Server Error',
          };
        }
        return {
          message: error.response.data.message,
        };
      }

      return { message: error.message };
    } catch {
      return { message: 'unknown error occurred' };
    }
  }

  setToken(token) {
    this.authToken = token;
  }

  getToken() {
    return this.authToken;
  }
}

const httpService = new HttpService();

const request = httpService.http;

export { httpService, request };
