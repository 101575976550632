// Api Configs

export const BASE_URL = process.env.REACT_APP_BASE_URL ?? ''
export const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT ?? 0
export const API_PREFIX = '/api/v1'
export const APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;
// ----------------------------------------------------------------------

export const COMPANY_NAME = 'SyncGlob'
export const PHONE = '+91 9602552282'
export const EMAIL = 'info@syncglob.com'
export const ADDRESS =
  'First Floor of Plot No. 63, Basant Kunj, Sri Ganganagar, 335001, Rajasthan, India'
