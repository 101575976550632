import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

export function RHFTextField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          variant="standard"
          {...field}
          fullWidth
          error={!!error}
          helperText={error ? error?.message : helperText}
          value={
            typeof field.value === "number" && field.value === 0
              ? "0"
              : field.value
          }
          onChange={(e) => {
            const { value } = e.target;
            field.onChange(value === "0" ? 0 : value);
          }}
          {...other}
        />
      )}
    />
  );
}
