import * as Yup from "yup";

import { Strings } from "@app/constants";

// ----------------ContactUsSchema-----------

export const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required(
    Strings.validation.fieldRequired(Strings.field.name)
  ),
  email: Yup.string().required(
    Strings.validation.fieldRequired(Strings.field.emailAddress)
  ),
  message: Yup.string().required(
    Strings.validation.fieldRequired(Strings.field.message)
  ),
  recaptcha: Yup.string().required(Strings.validation.pleaseVerifyCaptcha),
});
