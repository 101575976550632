
  
  export function TransformData(data) {
    const imagesByCategory = [[], [], [], [], []];
  
    data?.event?.forEach(event => {
      const matchingImages = data?.images.filter(image => image.eventId === event.eventId).map(image => image.image);
      const eventObject = {
        image: matchingImages,
        type: event.title,
        caption: event.description
      };
  
      if (event.category === 'Festival') {
        imagesByCategory[1].push(eventObject);
      } else if (event.category === 'Weekend Fun') {
        imagesByCategory[2].push(eventObject);
      } 
      else if (event.category === 'Birthday Celebration') { 
        imagesByCategory[3].push(eventObject);
      }
      else if (event.category === 'Activities') {
        imagesByCategory[4].push(eventObject);
      }
    });
  
    return imagesByCategory;
  }
  

  