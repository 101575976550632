import { Box, Drawer, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import aboutImg from '@app/images/about/about.png'
import teamImg from '@app/images/about/team.png'
import cultureImg from '@app/images/about/culture.png'
import { PATH_DASH } from '@app/routes/paths'
import useResponsive from '@app/constants/use-responsive'

import { aboutActive } from '@app/redux/slices/aboutSlice'

import TopNavBar from './top-nav-bar'

const AboutNavBar = () => {
  const dispatch = useDispatch()
  const isDrawerOpen = useSelector(state => state.about.isDrawerOpen)
  const isMobile = useResponsive('down', 'md')

  const navigation = useNavigate()
  return (
    <>
      <Drawer
        anchor={'top'}
        open={isDrawerOpen}
        onClose={() => dispatch(aboutActive(!isDrawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            marginTop: '64px'
          },
          display: 'flex'
        }}
      >
        <TopNavBar aboutNav />
        <Box
          p={4}
          width={'100%'}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            display: isMobile ? '' :'flex'
          }}
        >
          {/* --------------about us------------- */}
          <Stack
            // display={'flex'}
            minHeight={'180px'}
            p={4}
            flex={1}
            border={'1px solid #FAFAFA'}
            bgcolor={'#EFFDFF'}
            borderRadius={'10px'}
            justifyContent={'space-between'}
            onClick={() => {
              navigation(PATH_DASH.aboutUs)
              dispatch(aboutActive(false))
            }}
            style={{
              fontWeight: 'bold',
              color: 'black',
              cursor: 'pointer'
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={''}
              width={'100%'}
              spacing={3}
              pl={1}
              mb={1}
            >
              <Stack
                border={'1px solid #FAFAFA'}
                borderRadius={'10px'}
                bgcolor={'white'}
                p={1}
                height={50}
                width={50}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img width={'auto'} src={aboutImg} alt={''} />
              </Stack>
              <Typography color={'#36B9E3'} variant='h5'>
                About Us
              </Typography>
            </Stack>
            <Stack>
              <Typography color={'#79808A'} fontSize='13px'>
                Driving digital advancement with expertise and innovation, we're
                your trusted tech partners.
              </Typography>
            </Stack>
          </Stack>
          {/* ----------------culture--------------------- */}
          <Stack
            border={'1px solid #FAFAFA'}
            // display={'flex'}
            minHeight={'180px'}
            p={4}
            flex={1}
            bgcolor={'#E5F0FFB2'}
            borderRadius={'10px'}
            justifyContent={'space-between'}
            margin= {isMobile && '10px 0px'}
            onClick={() => {
              navigation(PATH_DASH.culture)
              dispatch(aboutActive(false))
            }}
            style={{
              fontWeight: 'bold',
              color: 'black',
              cursor: 'pointer'
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={''}
              width={'100%'}
              spacing={3}
              pl={1}
              mb={1}
            >
              <Stack
                border={'1px solid #FAFAFA'}
                borderRadius={'10px'}
                bgcolor={'white'}
                p={1}
                height={50}
                width={50}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img width={'auto'} src={cultureImg} alt={''} />
              </Stack>
              <Typography color={'#7A91F9'} variant='h5'>
                Culture
              </Typography>
            </Stack>
            <Stack>
              <Typography color={'#79808A'} fontSize='13px'>
                In our IT company, innovation flourishes through collaboration
                and a relentless pursuit of excellence.
              </Typography>
            </Stack>
          </Stack>
          {/* ------------------team--------------------- */}
          <Stack
            // display={'flex'}
            minHeight={'180px'}
            p={4}
            flex={1}
            bgcolor={'#E6F8FF'}
            border={'1px solid #FAFAFA'}
            borderRadius={'10px'}
            justifyContent={'space-between'}
            onClick={() => {
              navigation(PATH_DASH.ourTeam)
              dispatch(aboutActive(false))
            }}
            style={{
              fontWeight: 'bold',
              color: 'black',
              cursor: 'pointer'
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={''}
              width={'100%'}
              spacing={3}
              pl={1}
              mb={2}
            >
              <Stack
                border={'1px solid #FAFAFA'}
                borderRadius={'10px'}
                bgcolor={'white'}
                p={1}
                height={50}
                width={50}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img width={'auto'} src={teamImg} alt={''} />
              </Stack>
              <Typography color={'#36B9E3'} variant='h5'>
                Team
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                overflow: 'visible'
              }}
            >
              <Typography
                color={'#79808A'}
                fontSize='13px'
                sx={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  hyphens: 'auto'
                }}
              >
                Uniting brilliance, our agile team drives technological
                breakthroughs with passion and precision.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default AboutNavBar
