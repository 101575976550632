import reactNative from '../images/technologies/reactNative.png'
import flutter from '../images/technologies/flutter.png'
import angular from '../images/technologies/angular.png'
import vueJs from '../images/technologies/vueJs.png'
import node from '../images/technologies/nodeJs.png'
import python from '../images/technologies/python.png'
import dotNet from '../images/technologies/dotNet.png'
import nest from '../images/technologies/nestJs.png'
import next from '../images/technologies/nextJs.png'
import reactJs from '../images/technologies/react.png'
import postGre from '../images/technologies/postGre.png'
import mySql from '../images/technologies/mySql.png'
import aws from '../images/technologies/aws.png'
import azure from '../images/technologies/azure.png'
import gcp from '../images/technologies/gcp.png'

export const techData = [
  // App Development
  {
    id: 1,
    name: 'React Native',
    image: reactNative
  },
  {
    id: 2,
    name: 'Flutter',
    image: flutter
  },

  // Web Development
  {
    id: 3,
    name: 'Angular',
    image: angular
  },
  {
    id: 4,
    name: 'Vue JS',
    image: vueJs
  },
  {
    id: 9,
    name: "Next",
    image: next,
  },
  {
    id: 10,
    name: "React",
    image: reactJs,
  },

  // Backend Development
  {
    id: 5,
    name: 'Node JS',
    image: node
  },
  {
    id: 6,
    name: 'Python',
    image: python
  },
  {
    id: 7,
    name: 'Dot Net',
    image: dotNet
  },
  {
    id: 8,
    name: 'Nest jS',
    image: nest
  },
  // Database
  {
    id: 11,
    name: 'Postgre',
    image: postGre
  },
  {
    id: 12,
    name: 'MySQL',
    image: mySql
  },

  // Cloud Infrastructure
  {
    id: 13,
    name: "Azure",
    image: azure,
  },
  {
    id: 14,
    name: "AWS",
    image: aws,
  },
  {
    id: 15,
    name: "GCP",
    image: gcp,
  },
];
