import { all } from 'redux-saga/effects'

import { testimonialSagas } from './testimonialSaga'
import { portfolioSagas } from './portfolioSaga'
import { teamSagas } from './teamSaga'
import { cultureSagas } from './cultureSaga'

// single entry point to start all Sagas at once
export default function* rootSaga () {
  yield all([testimonialSagas(), portfolioSagas() , teamSagas(), cultureSagas()])
}
