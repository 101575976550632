import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '@app/constants'

const initialState = {
  culture: {},
}

const slice = createSlice({
  name: 'culture',
  initialState,
  reducers: {
    //
    getCulture (state) {
      state.culture.status = RequestStatus.RUNNING
    },
    getCultureSuccess (state, action) {
      state.culture.status = RequestStatus.SUCCESS
      state.culture.data = action.payload
    },
    getCultureFailed (state, action) {
      state.culture.status = RequestStatus.ERROR
      state.culture.error = action.payload
    }
    //
  }
})

export default slice.reducer
export const { getCulture, getCultureFailed, getCultureSuccess } =
  slice.actions
