export const OurTeamFoundersData = [
    {
        id: 3,
        leader: true,
        name: "Ashok Sharma",
        designation: "CEO",
        linkedin:
          "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAAwi-ywBPOyscPGI3vWlB-haH4KbdF7LSVE&keywords=ashok%20sharma&origin=RICH_QUERY_SUGGESTION&position=0&searchId=653b9db7-f449-4a9d-9dfd-7467535760b6&sid=SZx&spellCorrectionEnabled=false",
        image1:
          "https://storage.googleapis.com/nexus-c3115.appspot.com/ashok%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=uD%2BsFFZWUgtipNdk7LhN5SH1vXBgsl1m6F1R30PPZjqeazA4VthaurT6%2BFt9lC59Xk2oFrR%2FgpdSPv29SxL6NVIyxqyGXQVS4GCvLOkDMCyz7N7hA04mSnu8p611oZCtTBFfkXlaU2xM5Ki%2B6gqCz3YrVTCh8iDAS6F1YJtyVZv4mywmxdtoPJX4K6eTqZGg%2Bj4q2EpycnE1bdTTLGn9BiMU8iDjanPM0FIaCZAKxua4bMb84JUxEEqnmddG94vf51xgccWUIeIlvG1risOAimv2d2ZYZaUtmz2Vds%2FW8vOQqoxVCk3pLFji6TMIM4J2NBNv92nP%2FwAKIVH5U%2FrbcQ%3D%3D",
        image2:
          "https://storage.googleapis.com/nexus-c3115.appspot.com/AshokSharma.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=y5CGYyN9CNcig%2Fkh2RGTi5BuRyNxr4p8ZHOn2sNh9tKzbNYWdrF7a9QnF1pDsvevuzT%2Ff9XfNNdfas4J15%2FwQBuHq3W9O6fn0bKNSOnO5M2d4znBTbMxWxjQh7u2ngZqr%2BuAS4mR2rH69vvkCyn%2BMZ127gHul646b%2BRI76ACUg9ZWJE0Rsf0QAstnFJqbRY1bEHPWCax%2FfqAADElxt2YWaCsC3XPCbjyEdyg5IdVfwGQHajwqmtgr%2B3Op89DPtt85n4xChUNIB943%2FF%2F3%2FpY2h7BaLaCzQlIHLR0KrA7rdESPbygsdFB7NjQIPHuxJsIFHBioq1Jfl1MPbBtkdK5AA%3D%3D",
      },
  {
    id: 1,
    leader: true,
    name: "Pankaj Suthar",
    designation: "COO & Co-founder",
    linkedin:
      "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAABxwVuoB6DFr9awFZzKJRsQZcSODI0kbzEs&keywords=pankaj%20suthar&origin=RICH_QUERY_SUGGESTION&position=0&searchId=2548940e-dc94-4477-b84e-02e2acb2e5e2&sid=sz0&spellCorrectionEnabled=false",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/pankaj%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=cZHYQ1f0SGfOBi2R%2F7%2FK13dFRCP0ZQEQzhf0Bl56CQi2NyQ7zATaSF6AWL8xRXDQkaeiS9eSt7v4dy%2BZ1njGW8CQoHGqvh61KRS7e4wjZRq8NeZjKQbYu6B096XOwGgbbTSkbtVqsS3KSgCWmk1%2Ba65bBXM4LR588PXSLs6YTEJkIif6rHE7RFY6DJaXb5U1SnRzaKCILEjSgfQusrGcyoA7%2F6Nb%2Fc%2BzNDewVyX2t1HSpiqLlhoxtE%2FY4%2FQLLl6o7rnemrnAusmvroa37IqquhlKvPYQmQ9MlnrTDKaGQTAXuIyZHG6RjMSyiIpDQxy5RW7ICTq0wgO4c4Jv07PahA%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/PankajSuthar.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=hMLPPsUlsCuVqS9a%2FgDSv%2BwqyWf362w4rhJfiQwriIwfpmNrEWXQGXI7SVanIXHSm2S25gsXQC%2BlPPcAWnkVd%2Bkk%2BnEhiyhizrj40MDZBcRus5em2AwWrqg7RzJHp8P%2Fb6Nbz%2Fg34pl%2FUPETF89BAeTWpsepvSdRi0XCHZo06PKPG1hST8E6QGaLFa4g0INpNYX00STSp0aqSw%2BAss5Ihw4LOzFyHj8swdODWoqgKOGFquqT2uTBSxNwDYyV5B1yUS22VmeD8Sv0C7k4b3TtUHWmsVCBCSh6BiQIO8r5qRizqxJ%2BC%2Bljh9uH2Xwxph2slMl%2B2WYo337OJhtVs%2F5koA%3D%3D",
  },
  {
    id: 2,
    leader: true,
    name: "Jatin Soni",
    designation: "CTO & Co-founder",
    linkedin:
      "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAD6hM08BUOjU2TfWp2sgOM7xNZsm7C6AgNE&keywords=jatin%20soni&origin=RICH_QUERY_SUGGESTION&position=0&searchId=4214ab29-db0d-478d-830d-fff780794f0b&sid=Mk%3B&spellCorrectionEnabled=false",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/jatin%20soni%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=ssX7fDTBfqmiVYYGLo2fY%2FUwgqwGKUD1IBkYUPV8FqRGz0hoju0BB33MOvE9WvSqW9NVWof3MlRt0O4yau4YthvMaRfGhBok1wHhbEaMYbQdI2q44h0UHREpNILyneSrkW74elxqU5IrSuZDn1HahZKsCN9mSEFy%2BOxRVl%2FXyWawmE%2F6PqkjIvBwvPUXb9XZs6b%2BKvUjn8pM1xObe2UGK2LA7x7VliSWSv5miq27ebQQhn0BlSoeQbzs0tfPkZMj8GeTyrTdCL3HpKZwAr2WhxqQoUQYRHgWoqg4iLFnopvKGyuINxtuTE95pIdWKh71HJwIGSJKW3YiMfTB%2FJu7qg%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/JatinSoni.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=GgQphexlLEcM4VAC%2FW8dAq6%2B%2FWcKesUkgaRgOQ4lsG%2B3fw7eE0rrchVOf6bYi9ASTbJBQO2PKF7Lc%2FoNIC%2BQn5XGfmazHPtWMO1k1%2FuCNpoU2QQlEcHSq%2FDYVFUyY6QZmj2Gs4UrOB%2BHXExKwooDr58keOp0uFmmpg6AYRe6SFSrAufPpScQLSe53StiNXPm2aXEUT1TIAagORaIk0uurjZKg1YHT4j%2FTrbohjlgg11l0NdKONTSHFQNaSmCMaRpJFCwJzh%2Byl3pE9UAixgskg8N8C%2FIjH9YavAbt8DcCADfgPGQWQrg57GDCaU6YGCpXabJQqqbznjlt44aCRWUQg%3D%3D",
  },
  {
    id: 4,
    leader: true,
    name: "Pawan Verma",
    designation: "CTO & Co-founder",
    linkedin:
      "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAACqgiZ0BvAOalp-RxNj5Dyme4b_JILPvTew&keywords=pawan%20verma&origin=RICH_QUERY_SUGGESTION&position=0&searchId=f98a5940-0b11-4ce5-8ca7-6f1e56198841&sid=obJ&spellCorrectionEnabled=false",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/pawan%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=cHsy3aT3XKz9zbvEiL7Mptn1yzNOAxeA%2B6omIvICdikDzXmWWs6MaA5j5SQV21MxOcoAIKAxwKBBVUUBQpmp2Fq%2FmJzYNOlGF0SClz%2BkHpAJhaDRtIuMpDUF1pDDTULAbTTvRmfsBDdHt7p5xWnVxX8KtYtfOxNv1qNyJYJGQlhfV8LgZRmQVt0UjQ2mlerIYcpwcUGsXDoTmAqgWApxiJoWyoN6SrTKbotNhdRGq9ZRMBbwrvH3wUBWe9Hs7tNhfN000mPhw9syAoSSyvNRIMr6P%2FtIA29eaJcoNCTwk8So2yQXWihksMMS8aRcijoCXqI9TaRgind2mat%2FixZasg%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/PawanVerma.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=vYHp64pD%2FAmw5Q175mbIsY64AelzaLVnTkkx40Y%2FkPG77ztuy0zhbVSSHbyPJASchhrhQggYtluAP3KnWlIQJ63XC9lxsZjPl4BmVUInv7bSUZWmNqhNjjGze0FT1JdUSvsmIbi2wwSh8TGXf2a8Is2WSbKLtomMxnxUL0BSRy1Z9L%2FcW7l8lBJxuSlW%2F12Tkava1%2FPuTZZPmLy2zezVWx26mt81EIVYYc73CtGu2UA0qT7ScHb8gSgEAGCPFFUJgqpR%2B%2FLKWi3zukDLjeE5UI1Y4fKHQd%2B73SOslJ3LfxuHdg%2FP6Pm2yqJ8UF23POYqijhb%2BayP2I%2FyHvprlQOe0Q%3D%3D",
  },
  //   ---------------------team----------------------------
  {
    id: 5,
    leader: false,
    name: "Lavish Sachdeva",
    designation: "Software Engineer (Fullstack Developer)",
    linkedin: "Lavish Sachdeva",
    image1:
      "https://firebasestorage.googleapis.com/v0/b/nexus-c3115.appspot.com/o/LavishSachdeva.png?alt=media&token=f5c76ca5-8c60-48d2-a0fe-42030b3369c7",
    image2:
      "https://firebasestorage.googleapis.com/v0/b/nexus-c3115.appspot.com/o/LavishSachdeva.png?alt=media&token=f5c76ca5-8c60-48d2-a0fe-42030b3369c7",
  },
  {
    id: 6,
    leader: false,
    name: "Raman Gupta",
    designation: "Software Engineer (Fullstack Developer)",
    linkedin: "Raman Gupta",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/raman%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=uiXFVAY7FvwT9Tcyt6RMTBntB7QPyG2dME9jNYTsz59NsXORHnI%2FDy8K%2F8LJKRiUXbiafXf8lb%2BJrTRNwNYm6nRWJWM%2BAtY9uoDNr6%2FYP1S0MIDySaXuOgYjPlUofHOKR6mIPmHbtIQO2THPO%2FyVnNV6jw4PBTSfuNgc%2F%2Fl43Xz28fe2nbmLBq1qoomM8m58zFAkRKRymJCiUQ%2FyeITGIlSArhz9a4tZKyh6cx76KNzetuEG3rHo02%2FpgdoQvazV428oM4UTtoOoo6asnnK5oXb94tIgWvkCt6vBD4NpRTQhtHcbheLWUKafzMfW999eDfot42NiRqAsT9Wm7Mo3gQ%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/RamanGupta.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=xhiKdCRdBAmm8s%2BBO401zj%2FdSZ%2BagdN8RbA18AHmlD9WiC%2F3o%2BgFszX7Xp0gwA8IshOIa5YCvGF5peKfYDv%2FQg6abDI3HrGUogfkDT7%2F9zFCpwspDJogqNSuuXsqS9TuhRQJ0Zef%2BqwpVEGSmQ8GbC73r4PnBJA8gAFY%2Bk3J99%2BG2cMXz4sp2gqwXoxEGqnrJAOV0aHjIgAQwbIPRisQk94jo4mhAmCUsIdx8xuqF40P86GdUC1cgHEcci7xkdSHU4sNih4NOnCGtYkHEVoGPapAHtHIXYcU7gYb6%2B9ZqUWewta%2BXQe3hp7%2FbNaBprDfydMC6H3H66tsOtIxtYsjpA%3D%3D",
  },
  {
    id: 7,
    leader: false,
    name: "Vishal Verma",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Vishal Verma",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/vishal%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=STV%2Bg0FH0twJ9TVsh9qQxWlc1rvYM5Xx%2FlyD72syCGPXCPly%2BUyCAS6cKh6IUELqc7lpMhf4O6UXsVw%2BHQJuw7MhOJ5RTTjAPJTX0sYnLZhTSX5FpTUtNIgF3yG%2FhVgJq1kT%2FGDX7tSmP4HT%2FCVuPjsZxvlMI8j4BtyohPWKXmg1FhswWGSjmQV1A%2BgKM2ln5tBZjDNhCzzkkdr%2FdXHMXJCxfOSw1pAYGJeycW7XaPgjVhu7tlUW4WwPF%2BBtTMUJL4hb5S3rUTD55rniPTpjJTX%2BKChVT5Q1jYwOrtK3nZUXVCYE%2FEI0FjkwfkFRMBVRqCdbdPr8gZat8pszqYG9SQ%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Vishal.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=JXfjCseHcnQGYvK2B3%2BbimtsELRntFFqAZi7Hktw5PS9WxvPmpkSlDiPXJKQtWp6ls5ICQ3m2SRhu9kAWvyKG4tjV%2B4hZxHglvHNWnj2otHS%2Ba2BFlIk89NJstRy7tUGjJLqtUe%2BTMwwNx2%2BKueUVI8aSwKFZuNKKlB2oVKkzXfrLpgjMyeMFv8mOfGoXBryzxKGrJWsGo8nyqGbu%2B2mjomafgMpuq8oRQVXrNs%2FbxH8GdD76zrtapt8OZzTzBXm4UoFtzMp%2BF6Q6%2BeuFjffEQ9%2B6OYJ2UjQrL2xF0hJS1cps%2BfzTC7aelejZxFR0x7rNCcN77ELasq%2F5WydR6n%2BPA%3D%3D",
  },
  {
    id: 8,
    leader: false,
    name: "Pankaj Makkar",
    designation: "Software Engineer (FullStack Developer)",
    linkedin: "Pankaj Makkar",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/panjak%20makkar%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=b42u%2B2Mj7dV8FBsxQw7KbYrtFxdJW%2BcWQ%2Ft5znPcrQv5ISvOiQbCjmifJq0gFcOsivFXRUelCAkJawIJUXp5nEJeh4KbR4fnJQXEMDnBcPvHTQqM4xZ%2B2RWpB%2BEuSaCItFtQDTKi51JUpxjnHoCpnRD5b4XMroKUr0Tin47BC9Niy%2BlA39xsjvELKZwjZ0cQdiVNRcI3tMHqTUsLUVu3knbyZvkK7VxGfVsHek%2BDFU4DMKUWWvWIcxaw9lSdjuN0fo4BAe71pAt5VKUh2jPVSxALfC95e8d9pekzgeEpFxBbeP4w3N2C1hlRyCpMwzWhez%2FQCilrkHcqzHIPCfrArg%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/PankajMakkar.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=vZpoDgiWQKeEZi8XQgbJ5VthgPeIAMqaFSOfV0j9FJGkZgF2DfseiJ0zfO3K%2BN2fzk06KiEeFzlLrvMfNaHQZhMj6ai1pITnanbEHlxp48Un9WCE4ePAQxShtmgJPoztUXovoeWE8%2BbC9oac2xMMc%2F6oZOQ4tzBwUqdOg8nLbGrgxhb6LxzKnSaoMTzW05x%2FKLChMIMX%2FkKETdCCagJ6B76FkZrNzWVy6EKfn5MQiseQaFeYKKp60332%2F3X8ElomBUX%2Fq0hwdGGjxTeDMHp1PqR7cWnl3Egu8LbfdQJqKnt3ysDSHN5tD307Tsdksy3XQ1oGQIhMtncJUpNp8TrP%2Bw%3D%3D",
  },
  {
    id: 9,
    leader: false,
    name: "Yorush Verma",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Yorush Verma",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/yorush%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=CpCIoFkgkGfeiZ0gMMwXfxlrnyrzKt3iMYzBUxx4juTaM56x99g%2Bw3Liv2QRUQcsp6gOEMwu0i4Nu%2BkgfGQORftZuMN4sedi2t6jQmO4wfPzqP3AVsYeknw97CFem%2FhHcsxDFFEiRcq8wlRmGh3APAdfvMQkAXxcG7N%2BXBhhFA%2FUUXeyBKeMJhTBe0nMFCx4er5m9x5Cr2H%2F5Nr%2F1A9KV3dmCk81iLR2GD4HxxQmBOVjQA0mBzaWEVFF6Qe%2FlsMfNSOV9BZwU1aSmZtOEtCorYUAeUNvDIYUKxKODlSKj8p2Mdpq45mCMiaZu7OQCKOEs0EqswSqRDwufkVIcAVjsg%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Yorush.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=kh4pyNaCxO4dZPgyXJGEP4BUH%2BmAPruv%2FtYN4jmMYL82qv1M9%2F1prmn5Jedyv9GEm0A5P996JFZJuZCr3XWpsFbnXflt6QjejG9EBlif%2B20p%2FUned%2Fa9qpeqjXgHe1N1J32S6Pp%2BuKlEO1FCII3OXscxPa0HuweATc4rwGD58hoIbTpzZfiqWte1GkABE1iUKkXtj8vNLTBlA2xgTwpTZv2DV%2FmQKEVSWlgiys3K5UINHsPlmIMPGXSVmb0T4wlWxenGsY%2Bq1riVZ0S3nmAjJrVxwxwbzYFWi2d9Fmooc1CLdOH0uwzOZPm6K%2Bs0AGExQ4QsxiJgFLdyIdxBLBS8Ng%3D%3D",
  },
  {
    id: 10,
    leader: false,
    name: "Gajanand Verma",
    designation: "Software Engineer (Backend Developer)",
    linkedin: "Gajanand Verma",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/image%20113.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=c5WkRZXu28fjZGQa8O%2FflaWt3BSV4q2csiXHo1wQXV6RSL9BtMBO8X3O4B8eQqnH8BX4sTZLqylmqAV1DHMcwKDCl7pUDso9p9j%2B9fuxI8s5osPE67Qp%2FR%2FTVwEsA1QjP9umRKqjDVHddW0iJ6yz61eqV%2BtWEy6KrKcYMcbRh2foAVPUUynuQ8raam0YzuWTav7qUrBRjIlelgLTtUWoERvUBzkJ3RQmeZ7JXWhWho5U0lpjDFeJGdvHsMo2Vahp00wKjk3yaNjgi3WhL%2F2k6iZH%2B7u%2FOUNDR2Fyd3834wirqHOIFEbrl9TzTzAJr%2B3u0bdvW4W7FFRBf0JrdtRX6w%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Gajanand.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Q8qGa2nlfW%2Fe%2BaP5cMcc%2FHdVQgTSA%2BC%2BYaO29Wu1c2oAfg93QQdhe2ifdfk9y%2FW159yRrhx9FUIe46tzQ9GYpH86G516v3o5W4ZnBMEVWpH3QkNTyVIA9KdsNF0u47M7hQiNF716M1U8e%2F5EP6x2%2FlB9CR15aL9bca1XuDJq%2FRpz2lG92rM4DcVpd%2FVTauKURG4aCjV3nauvrCI6v2AvxarQuJORScQ0NecNq9VBcE0AdHGCsAXidC8pJXXk%2FZBCoTRUGJjeqwAXUGa24zLQ3yUCgIvz9EjGD6ftSj0dXVPB78drXVJN9xnADgvXo%2BfKVrYv%2FxBccajumnoIqNQUZw%3D%3D",
  },
  {
    id: 11,
    leader: false,
    name: "Harish Kumar",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Harish Kumar",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/harish%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=K3yKDpQbRMv0s2MOqHX2pLd4Lnk9V5iKimvFyyWl8usIRo7ysMud%2BXlrITV6d8IvoJXJ%2BhkSYA6JT1BcIrRBLF3G2maUXZpFpJfylpzKGv0Ex8xlyLk%2B89Sog1r6EvEFO0wWDOy921qPj2bHzey9Y78w9cjcFVUdrP7RghVuJG%2FArJQoDyiS39LyqiG40Ji%2BroO6GTA6%2FtypaNFYdAKHRbbMTbBOXYLWr6qLhUxPbhiMSNLDI6jYokrXwCWoGbK1rUm4kKQdUo4LCU11fuOVJLrMPGKUHmi4tUjtQIEkjceRF6g4P9gz82%2FPcpdKmVw7GzQU6d0wgBvXk0WZqgklmQ%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Harish.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=NLTfK60sQrldXjuQHZRcjviY0pdizaQ3JfZVbu%2FTYQC0Q9Swu5QeIXmRZJ4Mu%2Ftke3ormHj2mS0M3wMQ0R6ljqQ8dQoD6eDk1hdHTnJzA%2B5h4pXFbqDOx7Rc%2FLeuER6KvuUs9fIl2HgnYSLhOEvXX4cF9V1nLwS6zCJJsaBAaYCeM6X%2Fk2PIkKx5PVuNnYqFcs40w3HTx0QwKvRHNn2d4YuUaG35XDM6J986%2Fc%2Fz%2FCwVDixWFLiqfgqE1utIOv0MDkxw6TqhVN3qgDPA7JxzJZYurIib6k%2FROOkgRFlLAdim9ILH8O5Wdx%2BvWwXbijMxVtUgFuKD5qthJMsHi5C%2BjQ%3D%3D",
  },
  {
    id: 12,
    leader: false,
    name: "Vivek Batra",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Vivek Batra",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/vivek%20sir1.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=JkW4RDooTQe1Dri6p7lzBtVwWAh1r%2FX%2BKwdnQafqeONhF0%2BP%2FYfCf1a3LYWSVf2PC8aieAUrTiajEyNRkh%2B6uKgskccaDYyxs6LLBWYKY6%2F3hxxiFEUNo3PozE52LescslQMBpDNRFTa4m7kz5RQFvJgkkFrDE85IIc4zmtyJ12loWRuwIY3bFMdpmvubgXJwRxX%2BCc0IMZfOfCz%2FM1TkqUeDlFINefL1G4o7GhqlCoQ%2BoWybxW2N5jgDxbUfzYBJ%2Br8I5wmKj1JJa88%2FzowMSYllnfk%2Fk5jKt1RStqt789lnu8wJ83sTTfYKZzReQPBjOYB4dokuzdLq8N5VB9MWw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Vivek.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=T6cjmP2GEmcE%2Fissne8UEsCNLD%2FASYw79cA%2BYD4cWU3mccQXqEVjPNOb%2Fge3n8X6Idr%2FjlKb2Am%2FeUNla7090%2F0oLndmDZBvtwdbTHnG3mPeJ8AmAW%2FdACbu5RbqotoyBOCA8Kiz%2FADVc3Wv1sfpkjNH7%2FifwZK7RZAQOUq5a8DSFMxwPFjIpGRz9%2BU9ynYYhwNJtEf8Ykijkp5x3P2mT5VPLHrQFo7H3Gm3uyV4E%2BiKwRHbn5xJ0epeRs17D%2B%2BguHsmSDidaXv4BNIhM7dS7uc%2BbrGw2bDqrm3QAgDpTAutSxy89xr6KZNkCU2TdD%2BRzmj0dNVcDod9ZQ8%2Fc9hiBA%3D%3D",
  },
  {
    id: 13,
    leader: false,
    name: "Manoj Kumar",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Manoj Kumar",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/manoj%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=qIcM1JpxrELKmjtCRq%2BvFHQS%2BRG28WLGISFPozpi76H9ttyqam2fpWKXytT3QMm7LqNux8N9ct4%2BpLa78EOCnfOlsVhqIQrfHVDe6rKulmLlQotAmy0jKhmzjoeCeFocWoCS4CCeDJneK%2FTff0si0oi9XdbzuNPBh6Z9VstR1i0fzMp%2BVi%2FLwI98qOltAeJGqBA3j%2BOy1pniRGePlvzi5dPGtu3gPuNi17XypHY14dCMcprO%2FJfU6S5RCNCyj3btUOwbCfcr7ogMTpp4Ltku8BZ%2FDinbFq850dLQFcArpIrT32puMV9jjBGOgIuyuKOtvBGfcle6mPekds49WWeMMw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Manoj.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Xgm8ll5BBxqx9TnLrR0xgIVTYdeqmUTdrPT%2B449sqIYBlFB9x0hhj0Thf%2FO82AwSrcFGTtIFd4rYi7ralVdy0BV87sI314x2fejkHV4pI5cpjT3tzhCya4olBakE4mP21FvM0WgYzmoDBtjzSd8gXjpWplrihubzyYJ8my%2BwXRVZ21Q311p6bDzuCdjimDM6cvUW%2Fo5uwQzSZJu1UHBCebS5FdmWbXF4OsxHiSX94Tu6Sk1gH34i6J%2B2JDl14SvJtaKI76vdvQvV%2BqGtPJ6pEYqmuaobqxWxsXa6JnrkPBHEzR%2BKhVcdhpV3ryHn7GLUJ0Qz1A3CORNmbC2%2BASf%2BwQ%3D%3D",
  },
  {
    id: 14,
    leader: false,
    name: "Pooja verma",
    designation: "QA Analytics",
    linkedin: "Pooja verma",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/pooja%20mam%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=FYlT60YX9ng21l2UCaxNSnKsLh11HCplKHeSs6eU%2FaWraZmVeJurWNDgyDFn%2BoZtPc5oWf%2FWsna0XYgTf53t5j12zS1ZBOwf2KrYkbJQTya4Zdhqtqa%2F9dNPknN5Y3Ja7fYo7okC%2Fx8a7yOtVezDqM3yRBnS6vQTte0nJUG5wOGlOO%2ByQbTmMPuBT%2B8Fpeb7%2BBEzqeCes%2BaDhZ2rfn43mVKli2gPUKw2PIm0FLJmyFZ2G%2FS5Rz2Hwn3VU3XHJ3hPRdQPTltal5dUXPR0vziCH7M1%2BC0tmxKMyhGoSxBspG0qrn1Rl3i%2FV7ioijaKvvCNKX%2FwxzQkU1JM89aTE94Jzw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Pooja.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=2kFzy%2BHWJO2aL0V811tSa3Uv92oJ5tdTtBgqAfknK%2FTVxxUMz%2BdYL44Yc1b4tzF2G8fKJKKsgOVXxrNAw1DFE3VpAnXbGon%2BkAXK9vvvpQKm7k7ybjXSOTesPLfyLeFQprVBk3l77c6w0Q93DdoZL63U5qZN%2BkcOfpVVV4HCtlMcdL693Ewe37L4MuesHFfWrQP2YBxIRutEdDKTA%2Fo581X16HBPdFmOBySWGBk37WMw8%2BSx3YCoJSkSJeOa3xBkFNHTBaKOw0eDfazjTvEYeCMQLFA59qD8pBjwVNAkMhjfVuZhK0pcoN6qd9Py8BfgHEiF%2B7o3NcRS4TllzJPVQA%3D%3D",
  },

  {
    id: 15,
    leader: false,
    name: "Karina Goyal",
    designation: "Senior UI/UX Designer",
    linkedin: "Karina Goyal",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/karina%20mam%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=mOPDt8YDl3qagCHRp%2Fc3Z5%2BLNgPfMrAT64FO82Hze94SU1QnhGh8ZtmnVcIj%2FbXFxDo8girLQZjxcOfmtts6CuUR62sKVXJVG6n%2BPNwF7noCbhmQ1aRBOJuWqxEF9QyL6Q616RPzsT0qeLo6R0bKZ7IF1%2BPMmaJhkv%2BLUvpUxlXoJhPxwi8%2FhYXWEUUS5TQmwJTGJzch5laH%2FfBbuiKqlLEXq627U%2BK4sxRPm8O0ntcfAT3QFvwV%2FxwR8UQjyzc5PhVMR59SPixabfo1BzizBlByAx5dFRxZa5ec%2BzkkKOk20ua1410mDo3MWoDy5hZ8jXit1k0MNa0DdyAMYJJB2Q%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Karina.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=dERBejvNTaZX8it4PgQk%2Fw0OHsCQmAdNbhyjs6txLu%2Fv7OuZYxtB0b6uoM4YzNH3Crk%2B%2FPrBiNPtLwTxx%2B05zzpRpdMfEGEcIDRvHfIzGO63Uke8PL0oV25hhHOGrV2g7S1eqe9P4WMeCgVC9bTLszOGsm8ZM9PMJTBIvvR8j2MHAWu04RuKfxmHyJO%2BxluIFqEPcLxLJki70DlDmHacWIrW5O5UAuWCLVnDFtF9H5tM0TEJId9aLVg3bF7tICZjYrb6LLZv4J5pl2g9LhpjABglRw%2BlChFdIykp8%2Fc%2FOJAc%2BLg8W9Ahh2pALqsrbc3J%2BjtVeDbIek2gTOYj5lBRpw%3D%3D",
  },
  {
    id: 16,
    leader: false,
    name: "Aryan Arora",
    designation: "Software Engineer (Backend Developer)",
    linkedin: "Aryan Arora",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/aryan%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Jf4RmqpixuhYa4EFBvWl6KTwqOU1vc3pLLUZWKUlB3iFbOHV5b8CvookcjjlYABkNzYekwJd%2FRFXw%2BWg24v%2BKRwd6MyYpLwSt3XjAA0xVhq9LEyPS%2FqDIFYshAl3tjXLiibINtEWPzLKPuQs7VDKxm%2BdNU1Fm27oIQ3h%2BdeG3%2B0r55jo8MOKNnS2vICQGr9Czpm1zxebd2Czrt6CkXXkv59gajOGfRh%2FH6kXIIBTy5Co%2BgPp8BV2VTjCIk5LlVAwaAJPcg7NKU2WzSQ3LLZ2RbLqic2ejNOeXglUgpVqr0sSUkIkzevMpUsWwbktQle3vr60H3EMNYgg7JDuXvcZjg%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Aryan.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=IMsHjlazhT0KH3JgH5h2aTMgA0jKfORP%2Fx7cicEb%2F%2BT34qHhBfx%2FsegjFfCY9YRDptlwh9XF823c38ZrK%2Fw3APYBerui7RJ6jKVg%2FaxC3zMKuVnYeDX40vT61cje36e7IWst0RoDbist56cUciT4o%2BEQ2SYVDU2uKSM8ltJxPy0CDXSfB7F8%2FSzUws56zoMn1POJCJzR04YpxGjnRjILfPMAegcbY%2BjRgbBF5zGbEOf6r7UFS3iOi3zAlt%2Fwn2yIQ2VnPXpFrTYrcBSahjoyXxKndIVrLKOFDx1x8pAXP02uZw13OaMMmuC4HbVaR7yn9AGX6GPgfEmLocdtIHVe4g%3D%3D",
  },
  {
    id: 17,
    leader: false,
    name: "Manish Kumar",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Manish Kumar",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/manish%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=c0o4XI9C52ifmPu5t2aBoZfRde%2F%2FRpbhnN4wKlJUNzCy6rgmP%2BJ%2B5kQepIoo%2FE9rmfW%2BG6OjRPo0Rn0h89Zl4G9kEjPoVHMQMyHyXORbCzlIrd0CZWF7LbAm3eM0jCbI47JOxkaB8T0p93SIPIAhMwQaTsIeQXFc4IzbJiyQ0Y8LUFM8ZMf87%2FpgEYKcLEVW0my8lecrYPucIqrvpQaI%2FMOSxjeNNYA9KtevAVkWZbpM6%2FFWljjbp0%2FpSEhkmCJZo1514FauhO9szWvtZJA9h3%2Fpxxb%2FN4AG0ee8SmuCUA%2BM1uS4E0NTcGH1XI74KZpb8yYrB3kW1S7qkx%2B3J57FIA%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Manish.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=FEbSHStPj%2BcsE%2BA8mm2dtZiQfF89Yh3%2F00xsEAmpIqeBFiyAh0iA0FHfvZMG9lOvxAyv5AwaqjArcK77tEsZNmybgnm85vMndhe65ixfH3NCHK3bRT70PoHtwieq5IFvqI0QAVqjhuNpBlpDRufwCsrBQ7ZluDFT01d7qbNY9yZH6mmN84MLJSNao1mxPMcsO3zPMJw9DrCsxGBjnBeUjUBEObcd9j2Wsg9wnha5VKH9y9DTDtTv3G%2B7e5qCAiaWV5rUTDuL5TZ5NMqtTsdkHGuXkjIbvA7uydTm5FB1Mo%2F%2FewpuIDnYVZfrhwqPLr3wBGDVBuY9SZ6pqfXphC1KxA%3D%3D",
  },
  {
    id: 18,
    leader: false,
    name: "Somya Prakash Agarwal",
    designation: "Software Engineer (Backend Developer)",
    linkedin: "Somya Prakash Agarwal",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/somya%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=0uD%2BpaGWwYMt79qjbfntKUUVYhnYO1vErQ0nSQ4pVn7FqlW%2BmB4L7uieJfk7GTS66fFzXLNVoDmDAU5Ol60AGI3eH1ueVwkXhcbOimz4AFVAJ9tEGkRGai%2BuRx19p7U%2Bapz1VeO%2FcQAHQHPcqxkqugAK%2FOkftqTJLRW3srjVaUusDZCpzeQzKT%2F4XeRX3DmWRct6KsVmNml2E%2BaSC33NCi5TRy6NIR2oV3RAo2PJGKT6DLeU7PTZwnjBoTW%2BjRUS8xkvkdnaxq72eMxjMkb91Z8e4ur38ZMM7f%2FhSex90DlPZ1dlP0BkGYXpD4rCTc4yZbgQJ5RybhwYqyYtEVHyxw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Somya.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=tK32trU8lfHNiAIUf33N%2F9VP2Bu3kkZmNe0wxqxRFpU2WEuuTjbfK0w10fQRA%2BX9yHlAHhgH42wKqe7anPvle1pbCJTiZg6geFJDomKDTNd9%2BoBXhiWRfQRdarPQm7PLuwMvTkQqR0Lmmz0wvdLG994W%2F9zcvfcBD2evwzm7aOKgWS%2BOPYjYofZ11ruo08mlNFHvL4LhVOB1rXfTD3OPPgbYT2qHkREN3O6%2BIcUlRPqR6Qv%2FtF1TWk6tBQnDGVtiLfKN99C2TxtjxHijxD7pcObZwqqoZ1j8FoIeYNzOXQgZeq2yxRYWY3XM%2FdssjuxZF7y14rX7kyQfEMp6iqU6KQ%3D%3D",
  },
  {
    id: 19,
    leader: false,
    name: "Priyanka Sachdeva",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Priyanka Sachdeva",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/priyanka%20mam%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=tW8jHCFA%2FoPl42G0ox2HoA85q9ZMXZzFrhQjoF4qcV9MJe2zuqOPTcyTNC02Vuhnh9vGFh5mIN1XKl8PfcPMv0kO6NuVofzuox1O8fY5mjDQHqNbfXlroRhBTdlBOH9%2FAQNjMxT7vpsgKLrOD3aOh%2BPZ97syaK7sAL7oXCyluJocUUNrkroIas6J8YvIiG4KjTExHZbxDqcMhp06cdWdrRBNQ7xYqscbpERYmHNEFVFATBhkL02%2BpwBLqckpCw%2FHYSFV1rCroP1PgePAQPGL53HGZ8XdjN%2FYqKBmirv1snJH0b7G%2B0TtTy2fDjS%2F%2FA%2F80wJjLUA9L7N4X31SII7jKA%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Priyanka.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=poR5tiMaqwKJf0K6sq0IYW48C5Pu12%2B6xjafDeehnBqnvacShGCKEDFj9N3%2Bb1fPYCZ1CIiT4v6uAixfeO28EJCLZ2C1xBNCzZbCl5w78l1nGHvi%2BB1tHfj5RzvVelQXkEOckVT9MbcZzRK4a15ZvODGPTo5DaDnAiHA%2FQ2XuXdvi8x0PFE%2F9qF1GHNqnAOvxTococcG3Si1e%2Bks0bJCpz5gD9lMXVO%2FIrOMeBX%2B%2B9yodDUSpnze7cvMDWNzdkIT10aDG2pAz7DenYWKJxCW8o1rCygy%2B8lKMya0o3EHgwioXNVgHvD7PWuRGNsFPNkfwzJyMxKFmEQFIK6vmaIC1g%3D%3D",
  },
  {
    id: 20,
    leader: false,
    name: "Dhriti Middha",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Dhriti Middha",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/drithi%20mam%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=BH6NRupiAnjW%2BYGXRauFTLcn7jBqQb1i5B2D1gY%2F0ENQvjcQ9wA3xHjvu12ozQLVs6w3GJrzWocLdOTw%2BumnKj5BgZ1WBLemTnpqKnFqXvWfMRNWjfQdZORdHQGoixOuGGWBjsrPjEzqP3zasP9K00WzmQBDIm1TP0BYWMqCVedlU987sNEd5WKOrboLC7NYFz9WKetRI31A4ooF57TYXYwfFI4bl%2FswY86H3Tj0d4drc9Wzj0T3BRVwpnNx37m1d2EL%2BTP6uwi376881eJXmmuKGG3o2GxDG8mFwIeSesg%2BXvn57CMPRGz%2BxT2K5K1QVG9e9dsv6HTVO5mT2GY1RA%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Dhriti.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=JJmUGhLPJHCRbfJ9oOUgQkdgxpNhCmDKQR4SYHJ1OPxT8J0RB4Baib2QXz13ok0jjGBD05NtfZ3%2BH%2FAfa5LvpE0oS%2FaZpZktsC5oRTTC%2FQehNRwPTvFqgTLJg9pJrRAt8g9jPLIZ%2FvPwaEd49DU1bpb7oal5osE7lpj9bbtzii3K3kVM1NHRLzRdyOph1ERMIOeabSVCiYfHAxyNUa5SQEfs1hRaSzc9V11EXzBAJnQ3lsQCXGKFX4Aau80jsG4Vp%2FYR3VqUM5jrG8TNozvGKI1L%2FRNoH9wyadf%2FujbWv8ye2pBLwgod4HtJQQjNTWfIRWR8jX7vYbHR%2FXgTLUBBiA%3D%3D",
  },
  {
    id: 21,
    leader: false,
    name: "Jatin Chauhan",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Jatin Chauhan",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/jatin%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=Ig8X8dYks77ek%2F3CPtAESxhLszWS%2Fnw%2F4V3tB%2BkzMZ0BlfpMTsY2iUlWZq4BhTqWFaK2hu%2BNlcEnQ2Rb0qW1xXWXAAZwpeG9e%2Bl4KCSkn6%2FKNwgQoFerLzpwehQ%2BRZSRsbrw%2FP%2BijF1SbtBoQBArAU7Ji0V8Bh9Xkk4VJTtu%2FRL7jzsKMMJIXLFv%2B9MOjqUmrNtLT34L0RIwIzicAWNe1ZRV7j0ZIw2qRrUt0zkSC2%2BejWnmES4Kj7OIGcPYO3pmVXgnmVAf8ycSn6lXpTNWR9J4R3YgcaFSt7%2BPVcGavxZTAC9RF8GyatXfyafZetbPdHGvpmOA4i7MwTwBbD7kvA%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/JatinChohan.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=MqVadI%2BwA%2BV8dwFHaWNYYo9uwmilbK%2BZU0s%2BDQ9bSlSgGf2i6oV4pxjXL7jU1sT7zoc%2FMKg2hwN66QYhafTVUWnkpJzSEwx9VjoRY7ArNYU%2BwaohINiUO8vXObkRrLzEdnbQ6ewf3gEUudVfscUUt%2F%2FiotFeiRxkY9SgfzA1Zbeo4WWX42BLbPWaitNDPUjFJtGvBWgcrloRuQ%2BYaoq9XmWk83QQ0M%2FyjLawA97Kdz8JG8VDHiF%2Bqm%2BubIZZ0xSETo28VraQ6YchHdDEJ8vCN5ZW7Qg0z%2Bx4r086R%2BkSdKcD%2Bt48MD%2Fn%2BjfMtAIzf7TXMWB096lyvpNVamXIWwvPyA%3D%3D",
  },
  {
    id: 22,
    leader: false,
    name: "Sunny Singh",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Sunny Singh",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/sunny%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=HTzB8gAf%2BOyjyFfmjaTRGRCWth%2BO6698inKsO0FPkzAcrquDH9itCMluxzFAkw5IibHaUuxPveKaT2q4tcazGymqFFcydDLnHhhQl2iAjATM38rCmWi%2FaDRS%2BxSA4JKb7WAxB%2BywWnALVJuhomqYO2qHmRoJ0VGxoFRGktDpu4SjRRm5ELaLmiZFK6XHalaHCeY4qtxqb0YiP9wiH%2FU%2B1sGw028ir1bs9AAfbblnDmbyGDVVHtC3U0QtyVtZiNOR556baY6MwONWzS3B%2FVRY%2BZi9clUGAiTUAB7HnmWC2%2FMy2WNPn%2BMhGltHAySL1LB2LgSy3xCTHzMMkRn5ROkvdw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/Sunny.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=dGnB1DSpJi9C8%2B%2Fhv37RGRpgcIJh710t3Wl0rD3YSKKyTdjMxLc4Ws7A0a8Sbp7KVeQNgID1w0JAh4LsErN9Qzd6Rk8XCdSZXHNy2gxJDVNQj2ecJeGeRcYTS%2Fy2y90p%2FwbWTU7Xa3g6tVqExvNXGHJP8%2BQ5JAkfTcpdwGH6B6qxx85HPis%2FUM22q546Mje2%2F%2BAvCgsKPo2LG8Z8kR1HL9%2BidIl%2F6cLi47ot2tyXDRrb9edRIOz1TzV2mURFq4fO0RLK59W2UEJGaFchdU7kdJ1WuH2uQvTyx%2FWi%2Bc%2F86wQ4A9KQ6955hNaz%2Fa4PPuYhqN75gpLaN9IJ9IQsEXPKDA%3D%3D",
  },
  {
    id: 23,
    leader: false,
    name: "Chirag Bhambra",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Chirag Bhambra",
    image1:
      "https://firebasestorage.googleapis.com/v0/b/nexus-c3115.appspot.com/o/images%2Fchirag.png?alt=media&token=59dc5abe-3e6a-4726-b7ec-a32454ae19d8",
    image2:
      "https://firebasestorage.googleapis.com/v0/b/nexus-c3115.appspot.com/o/images%2Fchirag.png?alt=media&token=59dc5abe-3e6a-4726-b7ec-a32454ae19d8",
  },
  {
    id: 24,
    leader: false,
    name: "Jatin Gupta",
    designation: "Software Engineer (Frontend Developer)",
    linkedin: "Jatin Gupta",
    image1:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/jatin%20gupta%20sir%201.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=0qAM8QhnrVNnOQTIsin%2FzYCbNTr%2BZDjUvZWQTeBG629uy5YBTygTN6N%2FfiBT7S%2BuKnnO0hHLUPx1eamh13zboFyHvaJUr9Uag1qfC50BfyfbnwyFXCrH04xrRkIpditUs7s1IZnCJT7BgcHwVoZtLjlgerCADuu0pcMAvahBL1UOgQBEHrdGuIAPCiJ3zMV2v%2BmozpTE0xc8bN3FbgKVkEmAEYxUerG8LD0GWLF%2Fs%2FkIjVYH%2FAzh3SthlHRmPMdeidU7ZMC2VNw8B4vfmvjWjaebb9ajPRA0XOEznmQe%2FwVW75jY5shkadtUxlEiwHdctY0MwZTwxnAd5554p3%2Fvqw%3D%3D",
    image2:
      "https://storage.googleapis.com/nexus-c3115.appspot.com/JatinGupta.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=tKK3Kk7efh7Ngb77Zd8lyvUTehscpJVKPF3NqvFMoT45h%2F5dX%2F6iMNiHclFG7OhFRRx79lNkzxGpTQJkO%2Fz0e3AxtFOz8XfB9TeyaJhBBW7W9koO7C1UIqvhllDa0QaFnQ%2BjJ0t8KJPurF7jYM8BcaZfZNqb7uqvN%2F0d3hflP8X6tZM77ktcjlJRaMkrokz4VU1n2C1Ij5r7QXFMgZfNpKrGstRQ2W4a2vZZw%2FvaMsS5BRQVokDdGcMnjzaZwzUaQG%2FGRS9WYPgMRPvz0mP2CuWFzAeX27%2F1%2BuMoMyIiVmB4V5i5URdwD4YEFBrIazDCMpdpjJZ%2BZaOWJaiwhWbAyw%3D%3D",
  },
];
