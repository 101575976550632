import { Navigate, Outlet, useRoutes } from 'react-router-dom'

import { HomePage } from '@app/pages/view'

import { PATH_DASH } from './paths'
import TopNavBar from '@app/pages/top-nav-bar/top-nav-bar'
import AboutUs from '@app/pages/about-us/about-us'
import Culture from '@app/pages/culture/culture'
import { Team } from '@app/pages/our-team/team'
import Support from '@app/pages/support/support'
// import TopNavBar from '@app/pages/top-nav-bar/top-nav-bar'

// ----------------------------------------------------------------------

export function Router () {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_DASH.home} />
    },
    {
      element: <Outlet />,
      children: [
        {
          path: PATH_DASH.root,
          element: <TopNavBar />,
          children: [
            { path: PATH_DASH.home, element: <HomePage /> },
            { path: PATH_DASH.aboutUs, element: <AboutUs /> },
            { path: PATH_DASH.culture, element: <Culture /> },
            { path: PATH_DASH.ourTeam, element: <Team /> },
            { path: PATH_DASH.support, element: <Support /> }
          ]
        }
      ]
    }
  ])
}
