import { all, call, put, takeLatest } from 'redux-saga/effects'

import { request } from '@app/services'
import { getCachedData, setCachedData } from '@app/utils'

import { ApiEndPoints } from '../../services'
import {
  getTestimonial,
  getTestimonialSuccess,
  getTestimonialFailed
} from '../slices/testimonialSlice'

import { TestimonialData } from '@app/mock/testimonialData'

function* getTestimonialSaga (action) {
  try {
    const cachedData = getCachedData('testimonials')

    if (cachedData) {
      yield put(getTestimonialSuccess(cachedData))
    } else {
      yield put(getTestimonialSuccess(TestimonialData))
    }

    const result = yield call(request.get, ApiEndPoints.GET_TESTIMONIAL)

    yield put(getTestimonialSuccess(result.data))
    setCachedData('testimonials', result.data)
  } catch (err) {
    yield put(getTestimonialFailed(err))
  }
}

function* testimonialSagas () {
  yield all([takeLatest(getTestimonial, getTestimonialSaga)])
}

export { testimonialSagas }
