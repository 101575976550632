import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Stack, Typography, useScrollTrigger } from '@mui/material'
import { Typewriter } from 'react-simple-typewriter'
import { motion } from 'framer-motion'

import { PATH_DASH } from '@app/routes/paths'
import { Iconify } from '@app/components'
import { Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import ServicePage from '../Service/service-page'
import TopNavBar from '../top-nav-bar/top-nav-bar'
import homeImg1 from '../../images/hero/homeImg1.jpg'
import homeImg2 from '../../images/hero/homeImg2.jpg'
import OurWork from '../our-work/our-work'
import Testimonial from '../testimonial/testimonial'
import homeBg from '../../images/hero/homeBg.png'
import Footer from '../footer/footer'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
}

const HomePage = () => {
  const isMobile = useResponsive('down', 'sm')
  const isTab = useResponsive('between', 'sm', 'md')
  const navigation = useNavigate()
  const { state } = useLocation()
  const topWindowRef = useRef(null)
  const serviceRef = useRef(null)
  const ourWorkRef = useRef(null)
  const testimonialRef = useRef(null)
  const supportRef = useRef(null)

  const isDark = useSelector(state => state.theme.isDark)
  const scrollToRef = ref => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const triggerScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70
  })

  useEffect(() => {
    if (state?.pageName === Strings.topBar.home) {
      window.scrollTo(0, 0)
    }
    if (state?.pageName === Strings.topBar.whatWeDo) {
      scrollToRef(serviceRef)
    }
    if (state?.pageName === Strings.topBar.ourWork) {
      scrollToRef(ourWorkRef)
    }
    if (state?.pageName === Strings.topBar.testimonial) {
      scrollToRef(testimonialRef)
    }
  }, [state])


  return (
    <>
      {/* ---------------------TopNavBar------------------------- */}

      <Stack>
        <TopNavBar
          triggerScroll={triggerScroll}
          scrollToService={() => scrollToRef(serviceRef)}
          scrollToHome={() => scrollToRef(topWindowRef)}
          scrollToOurWork={() => scrollToRef(ourWorkRef)}
          scrollToTestimonial={() => scrollToRef(testimonialRef)}
          scrollToSupport={() => scrollToRef(supportRef)}
        />
      </Stack>
      {/* -------------------Home------------------------ */}
      <Stack
        flexWrap={'wrap'}
        width={'100%'}
        alignItems={'center'}
        ref={topWindowRef}
        style={{
          backgroundImage: `url(${homeBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Stack
          pt={'10vh'}
          maxWidth={'1600px'}
          id='home'
          direction={'row'}
          pl={isMobile ? '20px' : '50px'}
        >
          {/* -----------left---------- */}
          <Stack
            flex={1}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Stack
              alignItems={(isTab || isMobile) && 'center'}
              flexWrap={'wrap'}
              pr={2}
              position={'relative'}
              gap={2}
              display={'flex'}
              component={motion.div}
              variants={containerVariants}
              initial='hidden'
              animate={'visible'}
            >
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                direction={'row'}
                padding={1}
                pr={2}
                position={'relative'}
                width={'fit-content'}
                sx={{
                  marginTop: { xs: 6, md: 'none' },
                  backgroundColor: '#03c1fe1a',
                  borderRadius: '20px',
                  fontFamily: 'Lexend, sans-serif'
                }}
                component={motion.div}
                variants={childVariants}
              >
                <Stack
                  height={'90%'}
                  justifyContent={isMobile ? 'start' : 'center'}
                >
                  <Iconify color={'#03c1fe'} icon='ph:dot-outline-fill' />
                </Stack>

                <Typography color={'#03c1fe'}>
                  {Strings.page_description.tagLine}
                </Typography>
              </Stack>
              <Stack
                position={'relative'}
                flexWrap={'wrap'}
                textAlign={isMobile ? 'center' : 'start'}
                alignSelf={isMobile ? 'center' : 'start'}
                alignItems={isMobile || isTab ? 'center' : 'start'}
                width={'95%'}
                component={motion.div}
                variants={childVariants}
              >
                <Typography
                  style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
                  fontSize={isMobile ? '7vw' : isTab ? '2.5rem' : '4vw'}
                  color={isDark ? 'white' : 'black'}
                  fontWeight={'bold'}
                >
                  {Strings.page_description.homeCaption}{' '}
                </Typography>
                <Typography
                  style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
                  fontSize={isMobile ? '7vw' : isTab ? '2.5rem' : '4vw'}
                  color={isDark ? 'white' : 'black'}
                  fontWeight={'bold'}
                >
                  your{' '}
                  <span
                    style={{
                      maxWidth: '100px',
                      display: 'inline-block',
                      color: isDark ? 'white' : 'black',
                      fontWeight: 'bold',
                      textDecoration: 'underline'
                    }}
                  >
                    <Typewriter
                      words={['Business', 'Agency', 'StartUp', 'SaaS']}
                      loop={500}
                      cursor
                      cursorStyle=''
                      typeSpeed={270}
                      deleteSpeed={200}
                      delaySpeed={1000}
                    />
                  </span>
                </Typography>
              </Stack>
              <Stack
                position={'relative'}
                textAlign={(isTab || isMobile) && 'center'}
                component={motion.div}
                variants={childVariants}
              >
                <Typography
                  variant='body1'
                  color={isDark ? 'lightgray' : 'gray'}
                >
                  {Strings.page_description.homeDetail}
                </Typography>
              </Stack>
              <Stack
                position={'relative'}
                direction={'row'}
                gap={2}
                mb={5}
                mt={2}
                component={motion.div}
                variants={childVariants}
              >
                <Button
                  onClick={() => navigation(PATH_DASH.support)}
                  style={{
                    gap: 10,
                    backgroundColor: '#3AC1EF',
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    padding: isMobile ? '' : '8px 30px',
                    textTransform: 'initial'
                  }}
                  variant='contained'
                >
                  {Strings.button.letsTalk}

                  <Iconify icon='quill:arrow-right' />
                </Button>
                <Button
                  onClick={() => scrollToRef(testimonialRef)}
                  style={{
                    color: isDark ? 'white' : 'black'
                  }}
                >
                  <Iconify icon='ic:baseline-arrow-right' />
                  {Strings.button.testimonial}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          {/* ------------right------------ */}
          <Stack
            height={'90vh'}
            ml={'1%'}
            flex={1}
            position='relative'
            sx={{ display: { xs: 'none', md: 'flex' } }}
            overflow={'hidden'}
            component={motion.div}
            initial={{ opacity: 0, x: 40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            <Stack
              width={'40%'}
              sx={{
                position: 'absolute',
                bottom: '5%',
                left: '8%',
                zIndex: 1,
                boxShadow: '17px -17px 0px rgba(58, 193, 239, 0.1)'
              }}
            >
              <img
                src={homeImg1}
                alt='homeImg1'
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </Stack>

            <Stack
              width={'75%'}
              sx={{
                position: 'absolute',
                top: '10%',
                right: '10%',
                zIndex: 0
              }}
            >
              <img
                src={homeImg2}
                alt='homeImg2'
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* --------------responsive screen----------------- */}

        <Stack
          sx={{ display: { xs: 'flex', md: 'none' } }}
          width={'100%'}
          py={3}
          height={isMobile ? '80vh' : '130vh'}
          position={'relative'}
          alignItems={'center'}
        >
          <Stack
            width={'69%'}
            height={'50%'}
            sx={{
              position: 'absolute',
              top: '35%',
              zIndex: 1,
              boxShadow: '10px -10px 0px rgba(0, 0, 0, 0.2)'
            }}
          >
            <img
              src={homeImg1}
              alt='homeImg1'
              style={{
                width: '100%',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover'
              }}
            />
          </Stack>

          <Stack
            width={'70%'}
            sx={{
              position: 'absolute',
              zIndex: 0
            }}
          >
            <img
              src={homeImg2}
              alt='homeImg2'
              style={{
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover'
              }}
            />
          </Stack>
        </Stack>
        <Stack height={'20px'} id='service'></Stack>
      </Stack>
      {/* ---------------service page---------------- */}

      <Stack ref={serviceRef}>
        <ServicePage />
      </Stack>

      {/* -----------------our work--------------------- */}
      <Stack ref={ourWorkRef}>
        <OurWork />
      </Stack>

      {/* -----------------testimonial--------------------- */}

      <Stack ref={testimonialRef}>
        <Testimonial />
      </Stack>

      {/* ---------------------footer------------------------ */}

      <Footer
        scrollToService={() => scrollToRef(serviceRef)}
        scrollToOurWork={() => scrollToRef(ourWorkRef)}
        scrollToTestimonial={() => scrollToRef(testimonialRef)}
      />
    </>
  )
}

export { HomePage }
