import {useRef, useEffect, useState } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { Iconify } from '@app/components'

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const ImageCard = ({ data, founders = false }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 })
  const imageRef = useRef(null)
  const [showLinkedIn, setShowLinkedIn] = useState(true)
  
  useEffect(() => {
    const img1 = new Image()
    const img2 = new Image()
    img1.src = data.image1
    img2.src = data.image2
  }, [data.image1, data.image2])

  const handleMouseEnter = () => {
    if (imageRef.current) {
      imageRef.current.src = data.image2
      setShowLinkedIn(true)
    }
  }

  const handleMouseLeave = () => {
    if (imageRef.current) {
      imageRef.current.src = data.image1
      setShowLinkedIn(false)
    }
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      lg={3}
      component={motion.div}
      variants={containerVariants}
      initial='hidden'
      animate={inView ? 'visible' : 'hidden'}
      ref={ref}
    >
      <motion.div variants={childVariants}>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center'
          }}
        >
          <img ref={imageRef} src={data.image2} style={{ height: '200px' }} alt='' />
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant='h6'>{data.name}</Typography>
            {founders && showLinkedIn &&  (
              <div
                style={{ marginLeft: 2, cursor: 'pointer' }}
                onClick={ () => window.open(data.linkedin, '_blank')}
              >
                <Iconify icon={'skill-icons:linkedin'} sx={{ mt: 1 }} />
              </div>
            )}
          </Stack>
          <Typography
            fontSize='13px'
            color='#79808A'
            sx={{ textAlign: 'center' }}
          >
            {data.designation}
          </Typography>
        </Stack>
      </motion.div>
    </Grid>
  )
}

export default ImageCard
