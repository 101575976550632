// -----------mob-------------
import bob from '../images/portfolio/Mob Protfolio/Bob.png'
import card from '../images/portfolio/Mob Protfolio/Card estimation.png'
import cessa from '../images/portfolio/Mob Protfolio/Cessa.png'
import fmo from '../images/portfolio/Mob Protfolio/fmo.png'
import grocer from '../images/portfolio/Mob Protfolio/Grocer21.png'
import groove from '../images/portfolio/Mob Protfolio/Groove sense.png'
import healthBuddy from '../images/portfolio/Mob Protfolio/Health Buddy.png'
import miyabi from '../images/portfolio/Mob Protfolio/MIYABI.png'
import nexus from '../images/portfolio/Mob Protfolio/NEXUS.png'
import partnext from '../images/portfolio/Mob Protfolio/Partnext.png'
import tempo from '../images/portfolio/Mob Protfolio/Tempo.png'
import vista from '../images/portfolio/Mob Protfolio/VISTA.png'
import ultimatum from '../images/portfolio/Mob Protfolio/ultimatum.png'

// ----------web------------
import webHealth from '../images/portfolio/Web Protfolio/Health Buddy.png'
import webIso from '../images/portfolio/Web Protfolio/Iso.png'
import webMiyabi from '../images/portfolio/Web Protfolio/MIYABI.png'
import webPetVestaAdmin from '../images/portfolio/Web Protfolio/Petvesta-admin.png'
import webPetVesta from '../images/portfolio/Web Protfolio/Petvesta.png'
import webTempo from '../images/portfolio/Web Protfolio/Tempo.png'
import webAiGen from '../images/portfolio/Web Protfolio/aigeneration.png'
import webFmo from '../images/portfolio/Web Protfolio/fmo.png'
import webFundsaiq from '../images/portfolio/Web Protfolio/fundsaiq.png'
import webGavin from '../images/portfolio/Web Protfolio/gavin.png'
import webGeokapti from '../images/portfolio/Web Protfolio/geokapti.png'

export const ourWorkData = [
  {
    id: 1,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: bob,
    link: 'www.test01',
    name: 'test mob'
  },
  {
    id: 2,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: card,
    link: 'www.test02',
    name: 'test mob 2'
  },
  {
    id: 3,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: cessa,
    link: 'www.test03',
    name: 'test mob 3'
  },
  {
    id: 4,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: fmo,
    link: 'www.test04',
    name: 'test mob 4'
  },
  {
    id: 5,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: grocer,
    link: 'www.test05',
    name: 'test mob 5'
  },
  {
    id: 6,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: groove,
    link: 'www.test06',
    name: 'test mob 6'
  },
  {
    id: 7,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: healthBuddy,
    link: 'www.test07',
    name: 'test mob 7'
  },
  {
    id: 8,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: miyabi,
    link: 'www.test08',
    name: 'test mob 8'
  },
  {
    id: 9,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: nexus,
    link: 'www.test09',
    name: 'test mob 9'
  },
  {
    id: 10,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: partnext,
    link: 'www.test10',
    name: 'test mob 10'
  },
  {
    id: 11,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: tempo,
    link: 'www.test11',
    name: 'test mob 11'
  },
  {
    id: 12,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: vista,
    link: 'www.test12',
    name: 'test mob 12'
  },
  {
    id: 13,
    category: 'MobileApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
    image: ultimatum,
    link: 'www.test13',
    name: 'test mob 13'
  },

  {
    id: 14,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webHealth,
    link: 'www.test14',
    name: 'test web 14'
  },
  {
    id: 15,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webIso,
    link: 'www.test15',
    name: 'test web 15'
  },
  {
    id: 16,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webMiyabi,
    link: 'www.test16',
    name: 'test web 16'
  },
  // {
  //   id: 17,
  //   category: 'WebApp',
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
  //   image: webPetVestaAdmin,
  //   link: 'www.test17',
  //   name: 'test web 17'
  // },
  {
    id: 18,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webFmo,
    link: 'www.test21',
    name: 'test web 21'
  },
  {
    id: 19,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webTempo,
    link: 'www.test19',
    name: 'test web 19'
  },
  {
    id: 20,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webAiGen,
    link: 'www.test20',
    name: 'test web 20'
  },
  {
    id: 21,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webPetVesta,
    link: 'www.test18',
    name: 'test web 18'
  },
  {
    id: 22,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webFundsaiq,
    link: 'www.test22',
    name: 'test web 22'
  },
  {
    id: 23,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webGavin,
    link: 'www.test23',
    name: 'test web 23'
  },
  {
    id: 24,
    category: 'WebApp',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed diam eget risus varius blandit sit amet non magna.',
    image: webGeokapti,
    link: 'www.test24',
    name: 'test web 24'
  }
]
