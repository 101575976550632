import React from 'react'
import Typography from '@mui/material/Typography'
import useResponsive from './use-responsive'

export const DarkGradientText = ({ text }) => {
  const isMobile = useResponsive('down', 'sm')

  const gradientStyle = {
    flexWrap: 'wrap',
    background: 'linear-gradient(180deg, #131d52, #030821)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  }

  return (
    <Typography fontWeight={'bold'} style={gradientStyle} variant={isMobile ? 'h3':'h1'}>
      {text}
    </Typography>
  )
}

