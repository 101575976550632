import img1 from "../images/companies/image1.png";
import img3 from "../images/companies/image3.png";
import infinity from "../images/companies/20infinity.png";
import femmes from "../images/companies/femmes.jpg";
import groov from "../images/companies/groov.png";
import iso from "../images/companies/iso.png";
import vee from "../images/companies/vee.png";
import exeeria from "../images/companies/Exeeria.png";
import crusoe from "../images/companies/crusoe.png";
import grocer from "../images/companies/Grocer.png";
import livingMenu from "../images/companies/LivingMenu.png";
import miyabi from "../images/companies/miyabi.png";
import petvesta from "../images/companies/petvesta.png";

export const companiesData = [
  {
    id: 1,
    image: groov,
  },
  {
    id: 2,
    image: petvesta,
  },
  {
    id: 3,
    image: iso,
  },
  {
    id: 4,
    image: exeeria,
  },
  {
    id: 5,
    image: femmes,
  },
  {
    id: 6,
    image: vee,
  },
  {
    id: 7,
    image: crusoe,
  },
  {
    id: 8,
    image: img1,
  },
  {
    id: 9,
    image: img3,
  },
  {
    id: 10,
    image: infinity,
  },
  {
    id: 11,
    image: grocer,
  },
  {
    id: 12,
    image: miyabi,
  },
  {
    id: 13,
    image: livingMenu,
  },
];
