import { createSlice } from '@reduxjs/toolkit';

const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    isDrawerOpen: false,
  },
  reducers: {
    aboutActive: (state ,action) => {
      state.isDrawerOpen = action.payload
    },
  },
});

export const { aboutActive } = aboutSlice.actions;
export default aboutSlice.reducer;

