import { persistStore, persistReducer } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import Storage from 'redux-persist/lib/storage'

import rootSaga from './saga'
import rootReducer from './rootReducer'
import themeReducer from './slices/themeSlice'

const persistConfig = {
  key: 'root',
  storage: Storage,
  whitelist: ['portfolioSlice', 'testimonialSlice'], // store data in persist
  blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  theme: themeReducer,
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      // }
      serializableCheck: false,
      immutableCheck: false
    }).concat(sagaMiddleware)
})

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
