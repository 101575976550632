import { createSlice } from '@reduxjs/toolkit'

import { RequestStatus } from '@app/constants'

const initialState = {
  portfolio: {}
}
const slice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    //
    getPortfolio (state) {
      state.portfolio.status = RequestStatus.RUNNING
    },
    getPortfolioSuccess (state, action) {
      state.portfolio.status = RequestStatus.SUCCESS
      state.portfolio.data = action.payload
    },
    getPortfolioFailed (state, action) {
      state.portfolio.status = RequestStatus.ERROR
      state.portfolio.error = action.payload
    }
  }
})

export default slice.reducer
export const { getPortfolio, getPortfolioFailed, getPortfolioSuccess } =
  slice.actions
