import React from 'react'
import { Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { DarkGradientText, GradientText, Strings } from '@app/constants'
import { companiesData } from '@app/mock/companiesData'
import useResponsive from '@app/constants/use-responsive'
import { useSelector } from 'react-redux'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const Companies = () => {
  const isMobile = useResponsive('down', 'sm')
  const isDark = useSelector(state => state.theme.isDark)
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 })
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  return (
    <Stack alignItems={'center'} justifyContent={'center'} gap={3}>
      {/* ------------heading------------- */}
      <Stack
        position={'relative'}
        alignItems={'center'}
        justifyContent={'center'}
        mt={5}
        component={motion.div}
        variants={headerVariants}
        initial='hidden'
        animate={headerInView ? 'visible' : 'hidden'}
        ref={headerRef}
      >
        <Stack position={'relative'} zIndex={0} top={2}>
          {isDark ? (
            <DarkGradientText text={Strings.page_title.clients} />
          ) : (
            <GradientText text={Strings.page_title.clients} />
          )}
        </Stack>

        <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
          <Typography
            fontWeight={'bold'}
            color={isDark ? 'white' : 'black'}
            variant={isMobile ? 'h3' : 'h2'}
          >
            {Strings.page_description.trustedBy}
          </Typography>
        </Stack>

        <Stack position={'relative'} width={'60%'} mt={-3}>
          <Typography
            variant='body1'
            color={isDark ? 'lightgray' : 'gray'}
            textAlign={'center'}
          >
            {Strings.page_description.companyDetail}
          </Typography>
        </Stack>
      </Stack>
      {/* ---------------boxes--------------- */}
      <Stack
        p={4}
        width={'90vw'}
        height={'100%'}
        backgroundColor='#FBFCFE'
        justifyContent={'center'}
        alignItems={'center'}
        gap={isMobile ? 3 : 6}
        direction={'row'}
        display={'flex'}
        flexWrap={'wrap'}
        component={motion.div}
        variants={containerVariants}
        initial='hidden'
        animate={inView ? 'visible' : 'hidden'}
        ref={ref}
      >
        {companiesData?.map(value => (
          <motion.div variants={childVariants} key={value?.id}>
            <Stack
              p={1}
              height={isMobile ? '95px' : '170px'}
              width={isMobile ? '95px' : '170px'}
              key={value.id}
              justifyContent={'center'}
            >
              <Stack
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img
                  src={value.image}
                  alt={value.name}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Stack>
            </Stack>
          </motion.div>
        ))}
      </Stack>
    </Stack>
  )
}

export default Companies
