import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from '@app/services';
import { getCachedData, setCachedData } from '@app/utils';

import { ApiEndPoints } from '../../services';
import {
  getCulture,
  getCultureSuccess,
  getCultureFailed
} from '../slices/cultureSlice';

import { ourCultureData } from '@app/mock/ourCultureData';

function* getCultureSaga(payload) {
  try {
    const cacheKey = payload && payload.payload ? `culture_${payload.payload}` : 'culture';
    const cachedData = getCachedData(cacheKey);

    if (cachedData) {
      yield put(getCultureSuccess(cachedData));
    } else {
      yield put(getCultureSuccess(ourCultureData));
    }

    let result;
    if (payload && payload.payload) {
      result = yield call(
        request.get,
        `${ApiEndPoints.GET_EVENT}?q=${payload.payload}`
      );
    } else {
      result = yield call(request.get, ApiEndPoints.GET_EVENT);
    }

    yield put(getCultureSuccess(result.data));
    setCachedData(cacheKey, result.data);
  } catch (err) {
    yield put(getCultureFailed(err));
  }
}

function* cultureSagas() {
  yield all([takeLatest(getCulture, getCultureSaga)]);
}

export { cultureSagas };
