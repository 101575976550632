export const ServiceData = [
    {
      id: 'stack1',
      icon: 'ant-design:mobile-twotone',
      heading: 'Mobile App Development',
      caption: 'We use revolutionary technologies like React Native, Flutter to make futuristic products.'
    },
    {
      id: 'stack2',
      icon: 'uim:web-grid-alt',
      heading: 'Web App Development',
      caption: 'We use revolutionary technologies like React, Angular, VueJS to make web products.'
    },
    {
      id: 'stack3',
      icon: 'carbon:development',
      heading: 'API Development',
      caption: "API's are the backbone of frontend. We use NodeJS, Python, Dot Net to build api's."
    },
    {
      id: 'stack4',
      icon: 'mdi:scheduled-maintenance',
      heading: 'Maintenance & Upgrades',
      caption: 'Maintenance is the most important part of any product. We do provide maintenance and upgrades after deployment.'
    },
    {
      id: 'stack5',
      icon: 'uim:graph-bar',
      heading: 'MVP And PoC Development',
      caption: 'We do MVP development - To proof concept of idea or launch mvp product need\'s tight timeline.'
    },
    {
      id: 'stack6',
      icon: 'uim:layer-group',
      heading: 'Complete Solutions',
      caption: 'We can help business to develop Mobile or Web app from scratch idea to reality through various phases - planning to deployment.'
    }
  ];
  