export const TestimonialData = [
  {
      "id": 1,
      "feedback": "SyncGlob Team did an excellent job.It's hard to find a Team who will give you a working product,but SyncGlob made sure all of features where working before asking to move on to next milestones.They are available pretty much anytime I had a question and met every deadline for testing a new build.",
      "clientId": 6,
      "projectName": "Ultimatum",
      "projectLink": "string",
      "client": {
          "id": 6,
          "firstName": "Alex",
          "lastName": "Estrella",
          "phone": "",
          "email": "a@gmail.com",
          "companyName": "",
          "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/alex.63cc72e25a98c8ebaceb.jpeg?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=huNV7LY6CD5I6b7%2FW43hHxsYWRuqWN%2BtXpjvVdSZAf67gR4QfCKhNSVfI1WlNATZT%2FPhD4qUXE4xJJX587nZewzEhG%2ByxK3K0cE%2FOKRIswNZjuiSDX5O8XkDfQypvK%2FMjncFAJZT4YyliobgSAOppn3q8PK2YsJGr2HDD82aAd3HNe0Y4y9tE4Y%2BxPglVWLxOZsaeB0YtgEac6T7in0E%2BvDpkOwpP%2FgKHIjKW%2FvbTPN75UXkcvqqrOSRPPX1OgeyZbCyie%2FpNqw%2B%2FJOj0A48YhVXMzHa0te3geHs12CsONTos2SCd1R788KJu%2FeJmnsO38UGnL17tkhffG2diV9oOQ%3D%3D",
          "status": true,
          "createdAt": "2024-07-23T11:11:36.696Z"
      }
  },
  {
      "id": 2,
      "feedback": "I have great experience with SyncGlob team form past 2 years and my 3 projects are successfully developed and launched by their team and I'm happy to start another project with them. If we talk about code quality and task management they are excellent.",
      "clientId": 8,
      "projectName": "GeoKapti",
      "projectLink": "string",
      "client": {
          "id": 8,
          "firstName": "Idan",
          "lastName": "Bachar",
          "phone": "",
          "email": "idan@gmail.com",
          "companyName": "",
          "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/image_2024_07_23T07_07_02_426Z.png?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=4uOxaILbBzvjuU%2FLtE%2FewyHh4IgpqPxMQ1O9hhxW9WDx3lW%2BXFxbkZZpV9XxUQF0Bn%2BXMZODWsDr4vNZTxfRXZ4%2FJ5xMKtkAmTkgmI6y6lj1CRm29e6hB8WMqfp77zxePdOC7Pr7coU0CWIlj8OJtJboCmJ0xkvxZT0GK%2FDQw7Q5Fhk5IIGK7dx%2BQ%2B3W7GbOGmldsKoAw9SGFOoa3ydSDg721SpDpsqdBSgi0M3c4yWFLW5RgRD7I78opJEZeegC3l0%2FeoHenv9M24YIOqcNjw64%2FkRHZyh18nMk9jB5KP5yYTOr79opHO%2BC2yghq7iALTajwSFsXKM1bHTWiLOJOQ%3D%3D",
          "status": true,
          "createdAt": "2024-07-23T11:21:00.535Z"
      }
  },
  {
      "id": 3,
      "feedback": "Innovate excels in developing cutting-edge IoT solutions. Their products are designed to seamlessly integrate with existing systems for enhanced connectivity",
      "clientId": 9,
      "projectName": "Cucurico",
      "projectLink": "string",
      "client": {
          "id": 9,
          "firstName": "Bob",
          "lastName": "Jones",
          "phone": "",
          "email": "bob@gmail.com",
          "companyName": "",
          "image": "https://storage.googleapis.com/nexus-c3115.appspot.com/image-1.1dadf7371286b57753e8.jpg?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=edoPBNhfEH5pGL5d4L39U7TeyZbrtckzpeOJpHnoZaRwwaArOmfWX7sMHZ1hlwga749lni1MIBwzfUkybaFDWld1SZOgjurH25I3Me7nmMmFsuNjyrbO9QmqmuirDwJxkhjbc0krVTT62Txtge2SA0lHsA9%2Bk2j2Tvmc675LjIrAPSM3MmSXEOeM6xu9rEtWEYtXlZcWzo4EftgO7gdv3AFb6rKiodxRq1VyDpRg63kxbDV3VpxsmBNSJ%2FB0zBydO%2B8rg20Byr4ffVPbTp5CFPvVn8QrDNyu36SRHK4Ur9WaN5EGhEyS44ZP4d5wGsBE2QAX5uyyBUNeN1ZkVRgoYA%3D%3D",
          "status": true,
          "createdAt": "2024-07-23T11:22:51.368Z"
      }
  },
  {
    "id": 4,
    "feedback": "Syncglob delivered outstanding results with impressive speed, creatively overcoming the challenges presented. They employed a straightforward approach that significantly reduced the initially estimated overhead. I am delighted with their performance and look forward to collaborating with them again in the future.",
    "clientId": 6,
    "projectName": "",
    "projectLink": "string",
    "client": {
        "id": 6,
        "firstName": "Raul",
        "lastName": "L",
        "phone": "",
        "email": "a@gmail.com",
        "companyName": "",
        "image": "http://storage.googleapis.com/nexus-c3115.appspot.com/alex.63cc72e25a98c8ebaceb.jpeg?GoogleAccessId=firebase-adminsdk-a1j6c%40nexus-c3115.iam.gserviceaccount.com&Expires=16730303400&Signature=huNV7LY6CD5I6b7%2FW43hHxsYWRuqWN%2BtXpjvVdSZAf67gR4QfCKhNSVfI1WlNATZT%2FPhD4qUXE4xJJX587nZewzEhG%2ByxK3K0cE%2FOKRIswNZjuiSDX5O8XkDfQypvK%2FMjncFAJZT4YyliobgSAOppn3q8PK2YsJGr2HDD82aAd3HNe0Y4y9tE4Y%2BxPglVWLxOZsaeB0YtgEac6T7in0E%2BvDpkOwpP%2FgKHIjKW%2FvbTPN75UXkcvqqrOSRPPX1OgeyZbCyie%2FpNqw%2B%2FJOj0A48YhVXMzHa0te3geHs12CsONTos2SCd1R788KJu%2FeJmnsO38UGnL17tkhffG2diV9oOQ%3D%3D",
        "status": true,
        "createdAt": "2024-07-23T11:11:36.696Z"
    }
},
]
