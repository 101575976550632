import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { Iconify } from '@app/components'
import { DarkGradientText, GradientText, Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import { ServiceData } from './service-data'
import Technologies from './technologies'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const ServicePage = () => {
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.4
  })

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 })

  const [hoverStates, setHoverStates] = useState({
    stack1: false,
    stack2: false,
    stack3: false,
    stack4: false,
    stack5: false,
    stack6: false
  })

  const isDark = useSelector(state => state.theme.isDark)
  const isMobile = useResponsive('down', 'sm')

  const handleMouseEnter = stackId => {
    setHoverStates(prevState => ({
      ...prevState,
      [stackId]: true
    }))
  }

  const handleMouseLeave = stackId => {
    setHoverStates(prevState => ({
      ...prevState,
      [stackId]: false
    }))
  }

  return (
    <>
      <Stack
        backgroundColor={isDark ? '#051722' : '#fff'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {/* ----------header----------- */}
        <Stack
          position={'relative'}
          alignItems={'center'}
          mt={5}
          pb={1}
          component={motion.div}
          variants={headerVariants}
          initial='hidden'
          animate={headerInView ? 'visible' : 'hidden'}
          ref={headerRef}
        >
          <Stack position={'relative'} zIndex={0}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.whatWeDo} />
            ) : (
              <GradientText text={Strings.page_title.whatWeDo} />
            )}
          </Stack>

          <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
            <Typography
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
              fontWeight={'bold'}
            >
              {Strings.page_description.serviceCaption}
            </Typography>
          </Stack>

          <Stack position={'relative'} bottom={30} width={'60%'}>
            <Typography
              variant='body1'
              color={isDark ? 'lightgray' : 'gray'}
              textAlign={'center'}
            >
              {Strings.page_description.serviceDetail}
            </Typography>
          </Stack>
        </Stack>

        {/* ------------------boxes-------------------- */}
        <Stack
          flexWrap={'wrap'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          component={motion.div}
          variants={containerVariants}
          initial='hidden'
          animate={inView ? 'visible' : 'hidden'}
          ref={ref}
        >
          {ServiceData.map(data => (
            <motion.div variants={childVariants} key={data.id}>
              <Stack
                m={2}
                width={isMobile ? 'auto' : '330px'}
                height={isMobile ? '' : '260px'}
                alignItems={'center'}
                justifyContent={'start'}
                onMouseEnter={() => handleMouseEnter(data.id)}
                onMouseLeave={() => handleMouseLeave(data.id)}
                border={'1px solid #F4F4F4'}
                borderRadius={'5px'}
                boxShadow={'0px 2px 3px rgba(0, 0, 0, 0.07)'}
              >
                <Stack
                  mt={3}
                  alignItems={'center'}
                  justifyContent={'center'}
                  color={
                    hoverStates[data.id]
                      ? 'white'
                      : isDark
                        ? 'white'
                        : '#03c1fe'
                  }
                  height={'80px'}
                  width={'80px'}
                  borderRadius={'50%'}
                  backgroundColor={
                    hoverStates[data.id]
                      ? '#03c1fe'
                      : isDark
                      ? 'black'
                      : '#e5f8fe'
                  }
                >
                  <Iconify width={35} icon={data.icon} />
                  <img
                    color={
                      hoverStates[data.id]
                        ? 'white'
                        : isDark
                        ? 'white'
                        : '#03c1fe'
                    }
                    alt=''
                    src={data.icon}
                  />
                </Stack>
                <Stack>
                  <Typography
                    textAlign={'center'}
                    variant='h5'
                    fontWeight='bold'
                    color={isDark ? 'white' : 'black'}
                  >
                    {data.heading}
                  </Typography>
                </Stack>
                <Stack px={2} mb={isMobile && 1}>
                  <Typography
                    textAlign={'center'}
                    variant='body1'
                    color={isDark ? 'lightgray' : 'gray'}
                  >
                    {data.caption}
                  </Typography>
                </Stack>
              </Stack>
            </motion.div>
          ))}
        </Stack>
        {/* ------------tech page--------------- */}

        <Technologies />

        {/* -----toChangeNavBtnClr---- */}
        <Stack height={'20px'} id='our-work'></Stack>
      </Stack>
    </>
  )
}

export default ServicePage
