import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { Iconify } from '@app/components'
import { Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'
import mapImg from '@app/images/about/mapImg.png'

const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}
const mapVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}
const detailVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const LocationPage = () => {
  const isMobile = useResponsive('down', 'sm')
  const isTab = useResponsive('between', 'sm', 'md')
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  })
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  })

  return (
    <Box>
      <Box
        textAlign='center'
        my={3}
        component={motion.div}
        variants={headerVariants}
        initial='hidden'
        animate={headerInView ? 'visible' : 'hidden'}
        ref={headerRef}
      >
        <Typography
          color={'black'}
          variant={isMobile ? 'h3' : 'h2'}
          fontWeight={'bold'}
        >
          {Strings.page_description.ourLocation}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        justifyContent='center'
        alignItems={'center'}
        my={3}
      >
        {/* --------map----------- */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent='center'
          alignItems='center'
          component={motion.div}
          variants={mapVariants}
          initial='hidden'
          animate={inView ? 'visible' : 'hidden'}
          ref={ref}
        >
          <Box width={'70%'}>
            <a
              href='https://www.google.com/maps/place/Syncglob+Private+Limited/@29.9119931,73.8588395,15z/data=!3m1!4b1!4m6!3m5!1s0x66bf000ad6d5dae7:0xa9285932b90820c7!8m2!3d29.9119758!4d73.8772936!16s%2Fg%2F11sczkdw87?entry=ttu'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={mapImg} alt='map' />
            </a>
          </Box>
        </Grid>

        {/* -----------address----------- */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent='center'
          alignItems='center'
          style={{ height: '100%' }}
          pr={2}
          component={motion.div}
          variants={detailVariants}
          initial='hidden'
          animate={inView ? 'visible' : 'hidden'}
          ref={ref}
        >
          <Grid
            container
            spacing={2}
            direction='column'
            justifyContent='center'
            alignItems='center'
            style={{ height: '100%' }}
          >
            <Grid
              item
              container
              alignItems='center'
              justifyContent={isMobile || isTab ? 'center' : 'flex-start'}
            >
              <Box color='#03C1FE' mr={1}>
                <Iconify icon='gg:phone' />
              </Box>
              <Typography color='gray'>{Strings.detail.phone}</Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justifyContent={isMobile || isTab ? 'center' : 'flex-start'}
            >
              <Box color='#03C1FE' mr={1}>
                <Iconify icon='material-symbols:mail' />
              </Box>
              <Typography color='gray'>{Strings.detail.info}</Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justifyContent={isMobile || isTab ? 'center' : 'flex-start'}
            >
              <Box color='#03C1FE' mr={1} pt={0.5}>
                <Iconify icon='carbon:location-filled' />
              </Box>
              <Typography color='gray' textAlign={'center'}>
                {Strings.detail.address}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LocationPage
