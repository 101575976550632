import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography, IconButton, Slide } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import userAvatar from '../../images/profile.png';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Iconify } from "@app/components";
import useResponsive from "@app/constants/use-responsive";
import "./slideShow.css";

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const resVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
};

const SlideShow = ({ slideImages }) => {
  const isMobile = useResponsive("down", "sm");
  const swiperRef = useRef(null);
  const swiperRespRef = useRef(null);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: resRef, inView: resInView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const [currentSlide, setCurrentSlide] = useState(0);
  const [open, setOpen] = useState(true);
  const [slideDir, setSlideDir] = useState("right");

  const isDark = useSelector((state) => state.theme.isDark);

  const goToNextSlide = () => {
    setSlideDir("left");
    setOpen(false);
    setTimeout(() => {
      setSlideDir("right");
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slideImages.length);
      setOpen(true);
    }, 300);

    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
    if (swiperRespRef.current) {
      swiperRespRef.current.slideNext();
    }
  };

  const goToPrevSlide = () => {
    setSlideDir("right");
    setOpen(false);
    setTimeout(() => {
      setSlideDir("left");
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slideImages.length - 1 : prevSlide - 1
      );
      setOpen(true);
    }, 300);
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
    if (swiperRespRef.current) {
      swiperRespRef.current.slidePrev();
    }
  };

  return (
    <>
      <Stack
        width={"100%"}
        sx={{ display: { xs: "none", md: "flex" } }}
        alignItems={"center"}
      >
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          overflow={"hidden"}
          width={"90vw"}
          sx={{
            backgroundColor: isDark ? "#030a30" : "#FBFCFE",
          }}
          direction={"row"}
          component={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          ref={ref}
        >
          <motion.div variants={childVariants}>
            <Stack
              zIndex={0}
              width={"80vw"}
              height={"30vh"}
              my={4}
              direction={"row"}
            >
              <Stack
                width={"50%"}
                justifyContent={"center"}
                alignItems={"center"}
                overflow={"hidden"}
              >
                <Slide direction={slideDir} in={open}>
                  <Typography
                    mr={3}
                    variant="h6"
                    color={isDark ? "lightgray" : "gray"}
                    flexWrap={"wrap"}
                  >
                    {slideImages[currentSlide]?.feedback}
                  </Typography>
                </Slide>
              </Stack>
              <Stack
                width={"50%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Swiper
                  simulateTouch={false}
                  allowTouchMove={false}
                  loop
                  effect={"cards"}
                  grabCursor={false}
                  centeredSlides={true}
                  height={"initial"}
                  style={{}}
                  slidesPerView={"1"}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 85,
                    depth: 200,
                    slideShadows: false,
                  }}
                  modules={[EffectCoverflow, Pagination, Navigation]}
                  // className='mySwiper'
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {slideImages.map((slide, index) => (
                    <SwiperSlide
                      key={index}
                      style={{ alignSelf: "center", height: "initial" }}
                    >
                      <Stack
                        bgcolor={isDark ? "#030a30" : "#FBFCFE"}
                        overflow="hidden"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                          <img
                            src={userAvatar}
                            alt={""}
                            style={{
                              height: 100,
                              width: 100,
                              // maxWidth: "100%",
                              // maxHeight: "100%",
                              // objectFit: "cover",
                            }}
                          />
                        <Stack
                          width="100%"
                          textAlign="center"
                          borderBottom={"50px"}
                        >
                        

                          <Typography variant="body1" color="black">
                            {slide?.client?.firstName} {slide?.client?.lastName}
                          </Typography>
                          <Typography variant="caption" color="#545454">
                            {slide?.projectName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Stack>
            </Stack>
          </motion.div>
        </Stack>
        <Stack
          position={"relative"}
          width={"90%"}
          direction={"row"}
          justifyContent={"space-between"}
          zIndex={1}
          bottom={"20vh"}
        >
          <IconButton
            onClick={goToPrevSlide}
            sx={{
              ml: 1,
              backgroundColor: "#F2FCFF",
              color: "#3AC1EF",
              "&:hover": {
                backgroundColor: "#3AC1EF",
                color: "white",
              },
            }}
          >
            <Iconify icon="fe:arrow-left" />
          </IconButton>
          <IconButton
            onClick={goToNextSlide}
            sx={{
              mr: 1,
              backgroundColor: "#F2FCFF",
              color: "#3AC1EF",
              "&:hover": {
                backgroundColor: "#3AC1EF",
                color: "white",
              },
            }}
          >
            <Iconify icon="fe:arrow-right" />
          </IconButton>
        </Stack>
      </Stack>

      {/* -----------for responsive screen--------------- */}

      <Stack
        width={"100%"}
        sx={{ display: { xs: "flex", md: "none" } }}
        alignItems={"center"}
      >
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          padding={4}
          width={"90vw"}
          sx={{
            backgroundColor: isDark ? "#030a30" : "#f2f3fa",
          }}
          component={motion.div}
          variants={resVariants}
          initial="hidden"
          animate={resInView ? "visible" : "hidden"}
          ref={resRef}
        >
          <Stack
            width={isMobile ? "70vw" : "40vw"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Swiper
              simulateTouch={false}
              allowTouchMove={false}
              loop
              effect={"coverflow"}
              grabCursor={false}
              centeredSlides={true}
              slidesPerView={"1"}
              coverflowEffect={{
                rotate: 0,
                stretch: 68,
                depth: 200,
                slideShadows: false,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="mySwiper"
              onSwiper={(swiper) => {
                swiperRespRef.current = swiper;
              }}
            >
              {slideImages.map((slide, index) => (
                <SwiperSlide key={index}>
                  <Stack
                    bgcolor={"black"}
                    overflow="hidden"
                    borderRadius="20px"
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                  >
                    {/* <img
                      src={slide?.client?.image}
                      alt={''}
                      style={{
                        borderRadius: '20px',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    /> */}
                    <Stack
                      position="absolute"
                      bottom="0vh"
                      bgcolor="rgba(200, 200, 200, 0.6)"
                      width="100%"
                      p={1}
                      textAlign="center"
                      borderBottom={"50px"}
                    >
                      <Typography variant="body1" color="black">
                        {slide?.client?.firstName} {slide?.client?.lastName}
                      </Typography>
                      <Typography variant="caption" color="#545454">
                        {slide?.projectName}
                      </Typography>
                    </Stack>
                  </Stack>
                </SwiperSlide>
              ))}
            </Swiper>
            <Stack
              position={"relative"}
              width={isMobile ? "90vw" : "70vw"}
              direction={"row"}
              justifyContent={"space-between"}
              zIndex={1}
              bottom={160}
            >
              <IconButton
                onClick={goToPrevSlide}
                sx={{
                  ml: 1,
                  backgroundColor: "#BFEBFA",
                  color: "#3AC1EF",
                  "&:hover": {
                    backgroundColor: "#3AC1EF",
                    color: "white",
                  },
                }}
              >
                <Iconify icon="fe:arrow-left" />
              </IconButton>
              <IconButton
                onClick={goToNextSlide}
                sx={{
                  mr: 1,
                  backgroundColor: "#BFEBFA",
                  color: "#3AC1EF",
                  "&:hover": {
                    backgroundColor: "#3AC1EF",
                    color: "white",
                  },
                }}
              >
                <Iconify icon="fe:arrow-right" />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            overflow={"hidden"}
          >
            <Slide direction={slideDir} in={open}>
              <Typography
                textAlign={"center"}
                px={3}
                variant="h6"
                color={isDark ? "lightgray" : "gray"}
                flexWrap={"wrap"}
              >
                {slideImages[currentSlide]?.feedback}
              </Typography>
            </Slide>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SlideShow;
