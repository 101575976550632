import React from 'react'
import { Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useInView } from "react-intersection-observer"

import { Strings } from '@app/constants'
import { techData } from '@app/mock/techData'
import useResponsive from '@app/constants/use-responsive'

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const Technologies = () => {
  const isMobile = useResponsive('down', 'sm')
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 })
  
  return (
    <>
      {/* ------------heading------------- */}
      <Stack my={5}>
        <Typography color={'black'} variant={'h2'} fontWeight={'bold'}>
          {Strings.page_description.technologies}
        </Typography>
      </Stack>
      {/* ---------------boxes--------------- */}
      <Stack
        py={4}
        width={'90vw'}
        height={'100%'}
        backgroundColor='#FBFCFE'
        justifyContent={'center'}
        alignItems={'center'}
        direction={'row'}
        display={'flex'}
        flexWrap={'wrap'}
        component={motion.div}
        variants={containerVariants}
        initial='hidden'
        animate={inView ? 'visible' : 'hidden'}
        ref={ref}
      >
        {techData.map(value => (
          <motion.div variants={childVariants} key={value.id}>
            <Stack
              m={3}
              key={value.id}
              boxShadow={'0px 0px 3px rgba(0, 0, 0, 0.1)'}
              borderRadius={'5px'}
              p={1}
              height={isMobile ? '90px' : '170px'}
              width={isMobile ? '90px' : '170px'}
              backgroundColor={'white'}
              justifyContent={'space-between'}
            >
              <Stack
                height={'100%'}
                m={1}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <img
                  src={value.image}
                  alt={value.name}
                  style={{
                    width: isMobile ? '60%' : '70%',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Stack>

              <Stack position={'relative'} bottom={'0px'} textAlign={'center'}>
                <Typography variant={isMobile ? 'caption' : 'subtitle1'}>
                  {value.name}
                </Typography>
              </Stack>
            </Stack>
          </motion.div>
        ))}
      </Stack >
    </>
  )
}

export default Technologies
