import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { selectTeamState } from '@app/redux/selectors'
import { getTeam } from '@app/redux/slices/teamSlice'
import { DarkGradientText, GradientText, Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import ImageCard from './image-card'
import Footer from '../footer/footer'

const Team = () => {
  const isDark = useSelector(state => state.theme.isDark)
  const isMobile = useResponsive('down', 'sm')
  const dispatch = useDispatch()
  const teamData = useSelector(selectTeamState)

  useEffect(() => {
    dispatch(getTeam())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const leaders = teamData?.filter(member => member.leader)
  const members = teamData?.filter(member => !member.leader)

  return (
    <Stack
      style={{
        background:
          'linear-gradient(240deg, rgba(214, 229, 234, 0.5) 20%, white 0%, rgba(214, 229, 234, 0.5) 20%, white 40%)'
      }}
    >
      <Stack
        position={'relative'}
        alignItems={'center'}
        mt={10}
        component={motion.div}
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: 'easeOut' }}
      >
        <Stack position={'relative'} zIndex={0} top={2}>
          {isDark ? (
            <DarkGradientText text={Strings.page_title.team} />
          ) : (
            <GradientText text={Strings.page_title.team} />
          )}
        </Stack>

        <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
          <Typography
            color={isDark ? 'white' : 'black'}
            variant={isMobile ? 'h3' : 'h2'}
            fontWeight={'bold'}
          >
            {Strings.page_description.ourDreamTeam}
          </Typography>
        </Stack>

        <Stack position={'relative'} width={'60%'}>
          <Typography
            variant='body1'
            color={isDark ? 'lightgray' : 'gray'}
            textAlign={'center'}
          >
            {Strings.page_description.teamDetail}
          </Typography>
        </Stack>
      </Stack>

      {/* --------------------- Our Leaders --------------------------- */}
      <Stack m={5}>
        <Typography color='242424' variant={'h4'} mb={5}>
          Our Leaders
        </Typography>
        <Grid
          container
          sx={{
            justifyContent: 'space-evenly'
          }}
        >
          {leaders?.map((data, index) => (
            <ImageCard key={index} data={data} founders />
          ))}
        </Grid>
      </Stack>

      {/* --------------------- Our Members --------------------------- */}
      <Stack m={5}>
        <Typography color='242424' variant={'h4'} mb={5}>
          Our Members
        </Typography>
        <Grid
          container
          sx={{
            alignItems: 'flex-start'
          }}
        >
          {members?.map((data, index) => (
            <ImageCard key={index} data={data} />
          ))}
        </Grid>
      </Stack>

      {/* ----------------footer--------------- */}
      <Footer />
    </Stack>
  )
}

export { Team }
