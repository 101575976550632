import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    isDark: false,
  },
  reducers: {
    themeActive: (state ,action) => {
      state.isDark = action.payload
    },
  },
});

export const { themeActive } = themeSlice.actions;
export default themeSlice.reducer;

