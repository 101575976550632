import { all, call, put, takeLatest } from 'redux-saga/effects'

import { request } from '@app/services'
import { getCachedData, setCachedData } from '@app/utils'

import { ApiEndPoints } from '../../services'
import { getTeam, getTeamSuccess, getTeamFailed } from '../slices/teamSlice'

import { OurTeamFoundersData } from '@app/mock/ourTeamData'

function* getTeamSaga () {
  try {
    const cachedData = getCachedData('team')

    if (cachedData) {
      yield put(getTeamSuccess(cachedData))
    } else {
      yield put(getTeamSuccess(OurTeamFoundersData))
    }

    const result = yield call(request.get, ApiEndPoints.GET_TEAM)

    yield put(getTeamSuccess(result.data))
    setCachedData('team', result.data)
  } catch (err) {
    yield put(getTeamFailed(err))
  }
}

function* teamSagas () {
  yield all([takeLatest(getTeam, getTeamSaga)])
}

export { teamSagas }
