import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { getPortfolio } from '@app/redux/slices/portfolioSlice'
import { selectPortfolioState } from '@app/redux/selectors'
import { Strings, GradientText, DarkGradientText } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'
import Carousel from '@app/pages/our-work/carousel'
import { Iconify } from '@app/components'

import deskBtn from '../../images/portfolio/desktop-btn.png'
import webBtn from '../../images/portfolio/web-btn.png'
import mobBtn from '../../images/portfolio/mobile-btn.png'
import Companies from './companies'

const pages = [
  { name: 'Web Apps', img: webBtn },
  { name: 'Mobile Apps', img: mobBtn },
  { name: 'Desktop Apps', img: deskBtn }
]

const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const childVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}
const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const OurWork = () => {
  const isMobile = useResponsive('down', 'sm')
  const dispatch = useDispatch()
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 })
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  })
  const isDark = useSelector(state => state.theme.isDark)

  const portfolio = useSelector(selectPortfolioState)
  const categorizedData = {}
  portfolio?.forEach(item => {
    const { category } = item
    const cleanCategory = category.trim()
    if (!categorizedData[cleanCategory]) {
      categorizedData[cleanCategory] = []
    }
    categorizedData[cleanCategory].push(item)
  })
  const categorizedPortfolio = [
    categorizedData['WebApp'],
    categorizedData['MobileApp'],
    categorizedData['Desktop App']
  ]
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedApps, setSelectedApps] = useState(
    categorizedPortfolio[selectedIndex]
  )
  const handleNavMenu = index => {
    setSelectedIndex(index)
    setSelectedApps(categorizedPortfolio[index])
  }

  useEffect(() => {
    dispatch(getPortfolio())
  }, [dispatch])

  return (
    <>
      <Stack
        backgroundColor={isDark ? '#051722' : '#fff'}
        justifyContent={'center'}
        alignItems={'center'}
        pt={isMobile ? '' : 5}
        gap={5}
      >
        <Stack
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
          component={motion.div}
          variants={headerVariants}
          initial='hidden'
          animate={headerInView ? 'visible' : 'hidden'}
          ref={headerRef}
        >
          <Stack position={'relative'} zIndex={0} top={2}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.portfolio} />
            ) : (
              <GradientText text={Strings.page_title.portfolio} />
            )}
          </Stack>

          <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
            <Typography
              fontWeight={'bold'}
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
            >
              {Strings.page_description.portfolioCaption}
            </Typography>
          </Stack>

          <Stack position={'relative'} width={'60%'} mt={-3}>
            <Typography
              variant='body1'
              color={isDark ? 'lightgray' : 'gray'}
              textAlign={'center'}
            >
              {Strings.page_description.portfolioDetail}
            </Typography>
          </Stack>
        </Stack>
        {/* ---------------nav bar------------------ */}
        <Box
          width={isMobile ? '90%' : '60%'}
          flexWrap={'wrap'}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            display: 'flex'
          }}
          component={motion.div}
          variants={containerVariants}
          initial='hidden'
          animate={inView ? 'visible' : 'hidden'}
          ref={ref}
        >
          {pages.map((page, index) => {
            return (
              <Stack
                component={motion.div}
                variants={childVariants}
                display={'flex'}
                height={isMobile ? '15vh' : '25vh'}
                py={3}
                flex={1}
                bgcolor={selectedIndex === index ? '#E6F8FF' : 'inherit'}
                borderRadius={'10px'}
                alignItems={'center'}
                justifyContent={'center'}
                key={index}
                onClick={() => handleNavMenu(index)}
                style={{
                  fontWeight: 'bold',
                  color: isDark ? 'white' : 'black',
                  cursor: 'pointer'
                }}
              >
                <Stack>
                  <img
                    width={'auto'}
                    height={50}
                    src={page.img}
                    alt={page.name}
                  />
                </Stack>
                <Stack
                  color={'#03C1FE'}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  <Iconify icon='octicon:horizontal-rule-24' width={40} />
                </Stack>

                <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Typography variant='button'>{page.name}</Typography>
                </Stack>
              </Stack>
            )
          })}
        </Box>

        {/* ----------------carousel------------------ */}

        <Carousel
          data={
            selectedApps ? selectedApps : categorizedPortfolio[selectedIndex]
          }
        />
      </Stack>
      {/* ------------companies---------------- */}
      <Companies />

      {/* -----toChangeNavBtnClr---- */}
      <Stack height={'20px'} id='testimonial' />
    </>
  )
}

export default OurWork
