import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  Button,
  Fab,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { Strings } from '@app/constants'
import { PATH_DASH } from '@app/routes/paths'
import { aboutActive } from '@app/redux/slices/aboutSlice'
import logoImg from '../../images/logo/logo-light.svg'
import homeBg from '../../images/hero/homeBg.png'
import { Iconify } from '@app/components'

const pages = ['Home', 'What we do', 'Our Work', 'Testimonial', 'About Us']

const TopNavBar = ({
  aboutNav = false,
  scrollToService,
  scrollToOurWork,
  scrollToTestimonial,
  scrollToHome
}) => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [activeButton, setActiveButton] = useState('home')

  const isDark = useSelector(state => state.theme.isDark)
  const isDrawerOpen = useSelector(state => state.about.isDrawerOpen)

  const handleAboutClick = () => {
    dispatch(aboutActive(!isDrawerOpen))
  }

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const handleNavMenu = page => {

    if (page === 'Home') {
      location.pathname === PATH_DASH.home
        ? scrollToHome()
        : navigation(PATH_DASH.home)
    }
    if (page === 'What we do') {
      location.pathname === PATH_DASH.home
        ? scrollToService()
        : navigation(PATH_DASH.home)
    }
    if (page === 'Our Work') {
      location.pathname === PATH_DASH.home
        ? scrollToOurWork()
        : navigation(PATH_DASH.home)
    }
    if (page === 'Testimonial') {
      location.pathname === PATH_DASH.home
        ? scrollToTestimonial()
        : navigation(PATH_DASH.home)
    }
    if (page === 'About Us') {
      handleAboutClick()
    }

    setAnchorElNav(null)
  }

  useEffect(() => {
    const handleScroll = () => {
      const serviceSection = document.getElementById('service')
      const ourWorkSection = document.getElementById('our-work')
      const testimonialSection = document.getElementById('testimonial')

      const scrollPosition = window.scrollY

      if (serviceSection && ourWorkSection && testimonialSection) {
        if (scrollPosition < serviceSection.offsetTop) {
          setActiveButton('home')
        } else if (
          scrollPosition >= serviceSection.offsetTop &&
          scrollPosition < ourWorkSection.offsetTop
        ) {
          setActiveButton('service')
        } else if (
          scrollPosition >= ourWorkSection.offsetTop &&
          scrollPosition < testimonialSection.offsetTop
        ) {
          setActiveButton('our-work')
        } else if (scrollPosition >= testimonialSection.offsetTop) {
          setActiveButton('testimonial')
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const buttonStyle = {
    fontWeight: 'regular',
    my: 2,
    display: 'block'
  }

  const triggerScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40
  })

  const triggerScrollButton = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70
  })

  function ScrollTop (props) {
    const { children } = props
    return (
      <Fade in={triggerScrollButton}>
        <Box
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          role='presentation'
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9 }}
        >
          {children}
        </Box>
      </Fade>
    )
  }
  return (
    <>
      {/* ------------------nav bar------------------- */}
      <Stack>
        <AppBar
          position='fixed'
          style={{
            backgroundColor: aboutNav ? 'white' : 'inherit',
            boxShadow: 'none'
          }}
        >
          <Stack
            width={'100vw'}
            pt={1}
            borderBottom={triggerScroll && '1px solid lightgray'}
            style={{
              backgroundImage: aboutNav ? `url(${homeBg})` : 'none',
              backgroundSize: aboutNav ? 'cover' : 'initial',
              backgroundRepeat: aboutNav ? 'no-repeat' : 'initial',
              backgroundPosition: aboutNav ? 'center' : 'initial',
              opacity: triggerScroll ? 0.85 : 1,
              backgroundColor: triggerScroll
                ? isDark
                  ? 'rgba(0, 0, 0, 0.6)'
                  : 'rgba(255, 255, 255, 0.6)'
                : 'inherit',
              backdropFilter: triggerScroll && 'blur(10px)',
              WebkitBackdropFilter: triggerScroll && 'blur(10px)'
            }}
          >
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
              <Stack
                justifySelf={'start'}
                sx={{
                  ml: 7,
                  maxHeight: 30,
                  display: { xs: 'none', md: 'flex' }
                }}
                component='img'
                alt='logo'
                src={logoImg}
              />

              {/* ---------------------menu buttons-------------------- */}

              <Stack
                width={'45%'}
                direction={'row'}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  gap: 2,
                  display: { xs: 'none', md: 'flex' }
                }}
              >
                <Button
                  onClick={() => {
                    if (location.pathname !== PATH_DASH.home || aboutNav) {
                      navigation(PATH_DASH.home, {
                        state: { pageName: Strings.topBar.home }
                      })
                      dispatch(aboutActive(false))
                    } else {
                      scrollToHome()
                    }
                  }}
                  sx={{
                    ...buttonStyle,
                    color:
                      location.pathname === PATH_DASH.home &&
                      activeButton === 'home' &&
                      aboutNav === false
                        ? '#03c1fe'
                        : 'black'
                  }}
                >
                  {Strings.topBar.home}
                </Button>
                <Button
                  disabled={aboutNav}
                  onClick={() => {
                    location.pathname === PATH_DASH.home
                      ? scrollToService()
                      : navigation(PATH_DASH.home, {
                          state: { pageName: Strings.topBar.whatWeDo }
                        })
                  }}
                  sx={{
                    ...buttonStyle,
                    color:
                      location.pathname === PATH_DASH.home &&
                      activeButton === 'service'
                        ? '#03c1fe'
                        : 'black'
                  }}
                >
                  {Strings.topBar.whatWeDo}
                </Button>
                <Button
                  disabled={aboutNav}
                  onClick={() => {
                    location.pathname === PATH_DASH.home
                      ? scrollToOurWork()
                      : navigation(PATH_DASH.home, {
                          state: { pageName: Strings.topBar.ourWork }
                        })
                  }}
                  sx={{
                    ...buttonStyle,
                    color:
                      location.pathname === PATH_DASH.home &&
                      activeButton === 'our-work'
                        ? '#03c1fe'
                        : 'black'
                  }}
                >
                  {Strings.topBar.ourWork}
                </Button>
                <Button
                  disabled={aboutNav}
                  onClick={() => {
                    location.pathname === PATH_DASH.home
                      ? scrollToTestimonial()
                      : navigation(PATH_DASH.home, {
                          state: { pageName: Strings.topBar.testimonial }
                        })
                  }}
                  sx={{
                    ...buttonStyle,
                    color:
                      location.pathname === PATH_DASH.home &&
                      activeButton === 'testimonial'
                        ? '#03c1fe'
                        : 'black'
                  }}
                >
                  {Strings.topBar.testimonial}
                </Button>
                <Button
                  onClick={() => handleAboutClick()}
                  sx={{
                    ...buttonStyle,
                    color:
                      location.pathname === PATH_DASH.ourTeam ||
                      location.pathname === PATH_DASH.culture ||
                      location.pathname === PATH_DASH.aboutUs ||
                      aboutNav
                        ? '#03c1fe'
                        : 'black'
                  }}
                >
                  {Strings.topBar.aboutUs}
                </Button>
              </Stack>

              <Stack
                justifySelf={'end'}
                direction={'row'}
                gap={2}
                sx={{ mr: 7, display: { xs: 'none', md: 'flex' } }}
              >
                <Button
                  onClick={() => {
                    navigation(PATH_DASH.support)
                    dispatch(aboutActive(false))
                  }}
                  style={{
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    padding: '7px 50px',
                    textTransform: 'initial'
                  }}
                  variant='contained'
                >
                  {Strings.button.letsTalk}
                </Button>
              </Stack>

              {/* -----------responsive top bar--------------- */}
              <Stack
                ml={3}
                sx={{ maxHeight: 30, display: { xs: 'flex', md: 'none' } }}
                component='img'
                alt='logo'
                src={logoImg}
              />

              <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleOpenNavMenu}
                  color='inherit'
                  sx={{
                    borderRadius: '5px',
                    bgcolor: '#3AC1EF',
                    padding: '3px',
                    mr: 3
                  }}
                >
                  <MenuIcon sx={{ color: 'white' }} />
                </IconButton>

                <Menu
                  id='menu-appbar'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' }
                  }}
                >
                  {pages.map(page => (
                    <MenuItem key={page} onClick={() => handleNavMenu(page)}>
                      <Typography textAlign='center'>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
              {/* ---------------------------- */}
            </Toolbar>
          </Stack>
        </AppBar>
      </Stack>
      <Outlet />
      {/* --------------------scroll to top--------------------- */}
      <ScrollTop>
        <Fab
          size='small'
          aria-label='scroll back to top'
          sx={{
            borderRadius: '3px',
            bgcolor: '#3AC1EF',
            color: 'white',
            '&:hover': {
              bgcolor: '#3AC1EF'
            }
          }}
        >
          <Iconify width={35} icon={'iconamoon:arrow-up-2-thin'} />
        </Fab>
      </ScrollTop>
    </>
  )
}

export default TopNavBar
