import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { getCulture } from '@app/redux/slices/cultureSlice'
import { selectCultureState } from '@app/redux/selectors'
import { DarkGradientText, GradientText, Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import { TransformData } from './transformData'
import DisplayImages from './display-images'
import Footer from '../footer/footer'

const pages = [
  'All',
  'Festivals',
  'Weekend Fun',
  'Birthday Celebrations',
  'Activities'
]

const Culture = () => {
  const isDark = useSelector(state => state.theme.isDark)
  const isMobile = useResponsive('down', 'sm')
  const dispatch = useDispatch()
  const cultureData = useSelector(selectCultureState)
  const transformedData = TransformData(cultureData)
  const allImages = transformedData?.flatMap(group =>
    group?.flatMap(item => item?.image || [])
  )
  const [imagesArr, setImagesArr] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [title, setTitle] = useState('All')

  const handleNavMenu = index => {
    setSelectedIndex(index)
    if (index === 0) {
      setImagesArr(allImages)
    } else {
      setImagesArr(transformedData[index])
    }
  }

  useEffect(() => {
    dispatch(getCulture())
  }, [dispatch])

  useEffect(() => {
    if (allImages.length > 0 && imagesArr.length === 0 && selectedIndex === 0) {
      window.scrollTo(0, 0)
      setSelectedIndex(0)
      setImagesArr(allImages)
    }
  }, [allImages])

  return (
    <>
      <Stack
        style={{
          background:
            '  linear-gradient(240deg, rgba(214, 229, 234, 0.5) 20%, white 0%, rgba(214, 229, 234, 0.5) 20%, white 40%)'
        }}
      >
        <Stack
          position={'relative'}
          alignItems={'center'}
          mt={10}
          pb={5}
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <Stack position={'relative'} zIndex={0} top={2}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.culture} />
            ) : (
              <GradientText text={Strings.page_title.culture} />
            )}
          </Stack>

          <Stack position={'relative'} zIndex={1} top={isMobile ? -25 : -55}>
            <Typography
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
              fontWeight={'bold'}
            >
              {Strings.page_description.cultureCaption}
            </Typography>
          </Stack>

          <Stack position={'relative'} width={isMobile ? '90%' : '60%'}>
            <Typography
              variant='body1'
              color={isDark ? 'lightgray' : 'gray'}
              textAlign={'center'}
            >
              {Strings.page_description.cultureDetail}
            </Typography>
          </Stack>
        </Stack>

        {/* ---------------nav bar------------------ */}
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          mb={5}
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <Box
            flexWrap={'wrap'}
            width={'60%'}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              display: 'flex'
            }}
          >
            {pages.map((page, index) => {
              return (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  key={page}
                  onClick={() => {
                    handleNavMenu(index)
                    setTitle(page)
                  }}
                  style={{
                    color: isDark ? 'white' : 'black',
                    cursor: 'pointer'
                  }}
                >
                  <Typography
                    variant='body2'
                    style={{
                      textDecoration: selectedIndex === index && 'underline',
                      color: selectedIndex === index && '#03C1FE',
                      fontWeight: 'regular'
                    }}
                  >
                    {page}
                  </Typography>
                </Stack>
              )
            })}
          </Box>
        </Stack>
        {/* -------------display images----------------- */}
        {imagesArr && <DisplayImages data={imagesArr} title={title} />}
      </Stack>
      {/* ---------------footer--------------- */}
      <Footer />
    </>
  )
}

export default Culture
