import { HashRouter } from 'react-router-dom'
import { Router } from './routes'
import { PersistGate } from 'redux-persist/integration/react'

import ThemeProvider from '@app/theme'

import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import AboutNavBar from './pages/top-nav-bar/about-nav-bar'

export default function App () {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter basename=''>
          <ThemeProvider>
            <Router />
            <AboutNavBar />
          </ThemeProvider>
        </HashRouter>
      </PersistGate>
    </Provider>
  )
}
