import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ourWorkData } from '@app/mock/ourWorkData';
import { ApiEndPoints, request } from '@app/services';
import { getCachedData, setCachedData } from '@app/utils';

import {
  getPortfolio,
  getPortfolioFailed,
  getPortfolioSuccess
} from '../slices/portfolioSlice';


function* getPortfolioSaga(action) {
  try {
    const cacheKey = 'portfolio';
    const cachedData = getCachedData(cacheKey);

    if (cachedData) {
      yield put(getPortfolioSuccess(cachedData));
    } else {
      yield put(getPortfolioSuccess(ourWorkData));
    }
    const result = yield call(request.get, ApiEndPoints.GET_PORTFOLIO);
    yield put(getPortfolioSuccess(result.data));
    setCachedData(cacheKey, ourWorkData);
  } catch (err) {
    yield put(getPortfolioFailed(err));
  }
}

function* portfolioSagas() {
  yield all([takeLatest(getPortfolio, getPortfolioSaga)]);
}

export { portfolioSagas };
