export const Strings = {
  validation: {
    fieldRequired: (field) => `Please enter the ${field}`,
    pleaseVerifyCaptcha: "Please verify the captcha",
  },

  field: {
    name: "Name",
    emailAddress: "Email Address",
    yourName: "Your Name",
    phone: "Phone (Optional)",
    subject: "Subject",
    message: "Message",
  },

  button: {
    testimonial: 'Testimonials',
    sendMessage: 'Send Message',
    letsTalk: `Let's Talk`,
  },

  page_title: {
    clients: 'CLIENTS',
    aboutUs: 'ABOUT US',
    whatWeDo: 'WHAT WE DO',
    portfolio: 'PORTFOLIO',
    testimonial: 'TESTIMONIAL',
    support: 'SUPPORT',
    culture: 'CULTURE',
    letsTalk: `LET'S TALK`,
    team: 'TEAM'
  },

  page_description: {
    companyDetail:
      'The solid foundation of trust we have built with our customer base',
    cultureCaption: 'Our Culture Gallery',
    cultureDetail:
      'Welcome to our office culture photo gallery, where every snapshot is a testament to the spirit and camaraderie that define us. From team-building activities to milestone celebrations, these images capture the heartbeat of our workplace community.',
    ourLocation: 'Our Location',
    ourEnvironment: 'Our Environment',
    knowDetails: 'Know Details About',
    ourCompany: 'Our Company',
    growBusiness: 'Grow your Business with us',
    tagLine: 'Development | Automation | Research | AI',
    homeCaption: 'Digital Solutions for ',
    homeDetail:
      'Digitalize your business to reach out customers worldwide with trending technologies. SyncGlob will help you to build your product in latest technologies React, Angular, React Native, Flutter, NodeJS.',
    technologies: 'Technologies',
    trustedBy: 'Trusted By',
    serviceCaption: 'Our Core Services',
    serviceDetail:
      'SyncGlob has extensive experience in mobile and web app development. We understand the modern business needs and how it can be potentially grow with technology. We are helping businesses to grow with following latest technologies.',
    portfolioCaption: 'Explore Our Work',
    portfolioDetail:
      'Check our work how we helped our clients to achieve their goals and dreams through the work that we do.',
    testimonialCaption: 'What Our Clients',
    aboutUs: 'Say About Us',
    contactUs: 'Contact Us',
    supportDetail:
      'Simply fill out the form with your information and we’ll be in touch shortly! Thank you!',
    info: ' __info@syncglob.com',
    allRights: ' © 2024 SyncGlob. All rights reserved',
    ourDreamTeam: 'Our Dream Team',
    teamDetail:
      'Welcome to the heart of our organization, where passion meets expertise and innovation flourishes. Get to know the dedicated individuals behind our success, each bringing unique skills and perspectives to the table. Together, we strive for excellence, pushing boundaries, and achieving remarkable results. Dive into the profiles below to discover the driving forces behind our collective journey.'
  },

  topBar: {
    testimonial: 'Testimonial',
    home: 'Home',
    contactUs: 'Contact Us',
    ourWork: 'Our Work',
    service: 'Service',
    whatWeDo: 'What We Do',
    aboutUs: 'About Us'
  },
  detail:{
    phone:'096025 52282',
    info:'info@stncglob.com',
    address:' Basant Kunj, Plot no 63, Shiv Cir, near Police Station Sadar'
  }
}
