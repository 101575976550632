import { combineReducers } from '@reduxjs/toolkit'

import themeSlice from './slices/themeSlice'
import aboutSlice from './slices/aboutSlice'
import testimonialSlice from './slices/testimonialSlice'
import portfolioSlice from './slices/portfolioSlice'
import teamSlice from './slices/teamSlice'
import cultureSlice from './slices/cultureSlice'

const appReducer = combineReducers({
  theme: themeSlice,
  about: aboutSlice,
  testimonialSlice,
  portfolioSlice,
  teamSlice,
  cultureSlice,
})

// Reset all redux data
const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
