import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { getTestimonial } from '@app/redux/slices'
import { selectTestimonialState } from '@app/redux/selectors'
import { Strings, DarkGradientText, GradientText } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'

import SlideShow from './slide-show'

const headerVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const Testimonial = () => {
  const isMobile = useResponsive('down', 'sm')
  const dispatch = useDispatch()

  const isDark = useSelector(state => state.theme.isDark)
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
    threshold: 0.4
  })

  useEffect(() => {
    dispatch(getTestimonial())
  }, [dispatch])

  const testimonial = useSelector(selectTestimonialState)

  return (
    <>
      <Stack
        backgroundColor={isDark ? '#051722' : '#fff'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={7}
      >
        <Stack
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={4}
          component={motion.div}
          variants={headerVariants}
          initial='hidden'
          animate={headerInView ? 'visible' : 'hidden'}
          ref={headerRef}
        >
          <Stack position={'relative'} zIndex={0} top={2}>
            {isDark ? (
              <DarkGradientText text={Strings.page_title.testimonial} />
            ) : (
              <GradientText text={Strings.page_title.testimonial} />
            )}
          </Stack>

          <Stack
            mb={-8}
            position={'relative'}
            zIndex={1}
            top={isMobile ? -25 : -55}
            maxWidth={'450px'}
            textAlign={'center'}
          >
            <Typography
              fontWeight={'bold'}
              color={isDark ? 'white' : 'black'}
              variant={isMobile ? 'h3' : 'h2'}
            >
              {Strings.page_description.testimonialCaption} <br />{' '}
              {Strings.page_description.aboutUs}
            </Typography>
          </Stack>
        </Stack>

        {/* -----------------detail------------- */}

        {testimonial && <SlideShow slideImages={testimonial} />}

        {/* -----toChangeNavBtnClr---- */}
        <Stack height={'20px'} id='contact' />
      </Stack>
    </>
  )
}

export default Testimonial
