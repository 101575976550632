// ----------------------------------------------------------------------

function path (root, subLink) {
  return `${root}${subLink}`
}

const ROOTS_DASH = ''

// ----------------------------------------------------------------------

export const PATH_DASH = {
  root: ROOTS_DASH,
  home: path(ROOTS_DASH, '/home'),
  aboutUs: path(ROOTS_DASH, '/aboutUs'),
  culture: path(ROOTS_DASH, '/culture'),
  ourTeam: path(ROOTS_DASH, '/ourTeam'),
  support: path(ROOTS_DASH, '/support')
}
