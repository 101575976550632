import React from 'react'
import Typography from '@mui/material/Typography'
import useResponsive from './use-responsive'

export const GradientText = ({ text }) => {
  const isMobile = useResponsive('down', 'sm')

  const gradientStyle = {
    flexWrap: 'wrap',
    background: 'linear-gradient(180deg, #d8dbed, #FFF)',
    opacity:0.7,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  }

  return (
      <Typography fontWeight={'bold'} style={gradientStyle} variant={isMobile && 'h1'} fontSize={ !isMobile && '5rem'}>
        {text}
      </Typography>
  )
}
