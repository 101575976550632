import { FormProvider as Form } from 'react-hook-form'

// ----------------------------------------------------------------------

export function FormProvider ({ children, flex, onSubmit, methods }) {
  return (
    <Form {...methods}>
      <form style={{ flex }} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  )
}
