import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'

import { Iconify } from '@app/components'
import { Strings } from '@app/constants'
import useResponsive from '@app/constants/use-responsive'
import { PATH_DASH } from '@app/routes/paths'
import footerBg from '@app/images/blog/footer.png'
import logoImg from '../../images/logo/logo-light.svg'
import { ADDRESS, EMAIL, PHONE } from '@app/configs'

const footerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
}

const Footer = ({ scrollToService, scrollToOurWork, scrollToTestimonial }) => {
  const location = useLocation()
  const navigation = useNavigate()
  const isMobile = useResponsive('down', 'sm')

  const homePath = PATH_DASH.home

  const handleFootMenu = page => {
    if (page === 'Service') {
      scrollToService()
    }
    if (page === 'Our Work') {
      scrollToOurWork()
    }
    if (page === 'Testimonial') {
      scrollToTestimonial()
    }
  }
  return (
    <>
      <Stack
        gap={isMobile ? 1 : 0}
        width={'100%'}
        p={5}
        direction={'row'}
        display={'flex'}
        flexWrap={'wrap'}
        style={{
          backgroundColor: 'blue',
          backgroundImage: `url(${footerBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Stack
          alignItems={isMobile ? 'center' : 'start'}
          height={'100%'}
          flex={1.5}
          minWidth={'200px'}
          component={motion.div}
          variants={footerVariants}
        >
          <img src={logoImg} style={{ width: '70%' }} alt='logo' />
          <Typography fontWeight='bold' m={2}>
            Follow Us
          </Typography>
          <Stack flexDirection='row' sx={{ alignItems: 'center' }}>
            <IconButton
              disableRipple
              disableFocusRipple
              href='https://www.facebook.com/profile.php?id=100088565146093'
              target='_blank'
              sx={{
                padding: 0,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&:active': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Iconify icon={'logos:facebook'} sx={{ ml: 2 }} />
            </IconButton>
            <IconButton
              disableRipple
              disableFocusRipple
              href='https://www.instagram.com/syncglob/'
               target='_blank'
              sx={{
                padding: 0,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&:active': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Iconify icon={'skill-icons:instagram'} sx={{ ml: 1 }} />
            </IconButton>
            <IconButton
              disableRipple
              disableFocusRipple
              href='https://in.linkedin.com/company/syncglob?trk=public_profile_topcard-current-company'
              target='_blank'
              sx={{
                padding: 0,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent'
                },
                '&:active': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Iconify icon={'skill-icons:linkedin'} sx={{ ml: 1 }} />
            </IconButton>
          </Stack>
        </Stack>

        <Stack
          alignItems={isMobile ? 'center' : 'start'}
          height={'100%'}
          flex={1}
          gap={2}
          minWidth={'200px'}
          component={motion.div}
          variants={footerVariants}
        >
          <Stack>
            <Typography fontWeight='bold'>Use Cases</Typography>
          </Stack>
          <Stack>
            <Typography
              onClick={() => {
                if (location.pathname !== homePath) {
                  navigation(PATH_DASH.home, {
                    state: { pageName: Strings.topBar.whatWeDo }
                  })
                } else {
                  handleFootMenu('Service')
                }
              }}
              sx={{
                cursor: 'pointer'
              }}
              variant='body2'
            >
              {Strings.topBar.whatWeDo}
            </Typography>
            <Typography
              onClick={() => {
                if (location.pathname !== homePath) {
                  navigation(PATH_DASH.home, {
                    state: { pageName: Strings.topBar.ourWork }
                  })
                } else {
                  handleFootMenu('Our Work')
                }
              }}
              sx={{
                cursor: 'pointer'
              }}
              variant='body2'
            >
              {Strings.topBar.ourWork}
            </Typography>
            <Typography
              onClick={() => {
                if (location.pathname !== homePath) {
                  navigation(PATH_DASH.home, {
                    state: { pageName: Strings.topBar.testimonial }
                  })
                } else {
                  handleFootMenu('Testimonial')
                }
              }}
              sx={{
                cursor: 'pointer'
              }}
              variant='body2'
            >
              {Strings.topBar.testimonial}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          alignItems={isMobile ? 'center' : 'start'}
          height={'100%'}
          flex={1}
          gap={2}
          minWidth={'200px'}
          component={motion.div}
          variants={footerVariants}
        >
          <Stack>
            <Typography fontWeight='bold'>About Us</Typography>
          </Stack>
          <Stack>
            <Typography
              onClick={() => navigation(PATH_DASH.culture)}
              sx={{
                cursor: 'pointer'
              }}
              variant='body2'
            >
              Culture
            </Typography>
            <Typography
              onClick={() => navigation(PATH_DASH.ourTeam)}
              sx={{
                cursor: 'pointer'
              }}
              variant='body2'
            >
              Teams
            </Typography>
          </Stack>
        </Stack>

        <Stack
          alignItems={isMobile ? 'center' : 'start'}
          height={'100%'}
          flex={1.5}
          gap={2}
          minWidth={'200px'}
          component={motion.div}
          variants={footerVariants}
        >
          <Stack>
            <Typography fontWeight='bold'>Use Cases</Typography>
          </Stack>
          <Stack gap={1} alignItems={isMobile ? 'center' : 'start'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Iconify icon='gg:phone' sx={{ color: '#03C1FE' }} />
              <Typography variant='body2' sx={{ textDecoration: 'underline' }}>
                {PHONE}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Iconify icon='material-symbols:mail' sx={{ color: '#03C1FE' }} />
              <Typography variant='body2'>{EMAIL}</Typography>
            </Stack>

            <a
              href='https://www.google.com/maps/place/Syncglob+Private+Limited/@29.9119931,73.8588395,15z/data=!3m1!4b1!4m6!3m5!1s0x66bf000ad6d5dae7:0xa9285932b90820c7!8m2!3d29.9119758!4d73.8772936!16s%2Fg%2F11sczkdw87?entry=ttu'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Stack direction={'row'} spacing={1}>
                <Stack pt={0.5} color={'#03C1FE'}>
                  <Iconify
                    icon='carbon:location-filled'
                    sx={{ color: '#03C1FE' }}
                  />
                </Stack>
                <Typography variant='body2'>{ADDRESS} </Typography>
              </Stack>
            </a>
          </Stack>
        </Stack>
      </Stack>

      {/* --------------------copyright--------------------- */}
      <Stack
        height={'10vh'}
        width={'100%'}
        justifyContent={'center'}
        textAlign={'center'}
        backgroundColor={'#3AC1EF'}
      >
          <Typography color={'white'}>
            {Strings.page_description.allRights}
          </Typography>
      </Stack>
    </>
  )
}

export default Footer
